import React, { useState, useRef, useEffect } from 'react'
import styles from './StepTitle.module.scss'
import { formatDate } from '../../../../../components/format'
import { doFetch } from '../../../../../components'
import { Dialog, Toast } from 'tdesign-mobile-react';
import { useNavigate, useLocation } from 'react-router-dom'

const StepTitle = (props) => {
    const navigate = useNavigate()
    const query = new URLSearchParams(useLocation().search)

    const { setTitle, gameId, setIsLoading, isSelf, title, curNickName } = props;

    const [showInputDialog, setShowInputDialog] = useState(false)

    const [routeFrom, setRouteFrom] = useState(false);

    useEffect(() => {
        if (query.get('routeFrom')) {
            setRouteFrom(query.get('routeFrom'))
        }
    }, [])


    let newName = '';
    const handleName = (event) => {
        newName = event.target.value
    }

    const getOtherPlayer = async () => {
        setIsLoading(true);
        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game/' + gameId, 'GET')
            let respJson = await res.json()
            console.log(respJson);

            setIsLoading(false);
            if (respJson.code === 0) {
                if (respJson.response.state == 5 || respJson.response.state == 7) {
                    if (respJson.response.intruments && respJson.response.intruments.length > 0) {
                        let intruments = '';
                        respJson.response.intruments.forEach((item) => {
                            intruments += item.instrumentTypeCN + '手：' + item.nickname + '\n'
                        })
                        Toast({ message: intruments })
                    } else {
                        Toast({ message: '该作品由' + curNickName + '独自创作', })
                    }
                }
                //当结算未完成，显示“其他乐手信息查询中，请稍等”
                else if (respJson.response.state == 9) {
                    Toast({ message: '其他乐手信息查询中，请稍等' })
                }
            }
            else if (respJson.code === 401) {
                navigate('/login?uri=' + encodeURIComponent('/game/step4?gameId=' + gameId));
            }
            else {
                setIsLoading(false);
                Toast.fail({ message: '查询失败，请重试' })
            }
        } catch (err) {
            setIsLoading(false);
            Toast.fail({ message: '查询失败，请重试' })
            return false;
        }
    }

    const goUserInfo = () => {
        navigate('/updateinfo');
    }

    const updateName = async () => {
        setIsLoading(true);
        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game', 'PUT',
                {
                    "gameId": gameId,
                    "title": newName
                }
            )
            let respJson = await res.json()
            console.log(respJson);

            setIsLoading(false);
            if (respJson.code === 0) {
                setTitle(newName)
                Toast.success({ message: '修改成功' })
            }
            else if (respJson.code === 401) {
                navigate('/login?uri=' + encodeURIComponent('/game/step4?gameId=' + gameId));
            }
            else {
                setIsLoading(false);
                Toast.fail({ message: '修改失败，请重试' })
            }
        } catch (err) {
            setIsLoading(false);
            Toast.fail({ message: '修改失败，请重试' })
            return false;
        }
    }

    const handleBack =()=>{
        if(routeFrom){
            navigate('/'); 
        }else{
            navigate(-1); 
        }
    }

    return (
        <div>
            <div className={styles.Header}>
                {routeFrom ?
                    (<img onClick={handleBack} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/player/icon-home.png" />)
                    : (<img onClick={handleBack} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/player/icon-back.png" />)
                }
                <h1>TXMusic</h1>
            </div>

            <div className={styles.title}>{routeFrom ? 'Step4' : ''} {title}
                {isSelf && <img onClick={() => { setShowInputDialog(true); }} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-edit.png" ></img>}
                {routeFrom ? '创作完成' : ''}</div>
            <div className={styles.desc}>
                快来听听 {curNickName}
                {isSelf && <img onClick={goUserInfo} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-edit.png" />}
                与
                <span onClick={() => { getOtherPlayer() }}>其他乐手</span>
                共创的音乐吧
            </div>
            <div className={styles.step} >
                <div className={styles.stepLine + ' ' + styles.deepColor}></div>
                <div className={styles.stepLine + ' ' + styles.deepColor}></div>
                <div className={styles.stepLine + ' ' + styles.deepColor}></div>

                <div className={styles.stepRound + ' ' + styles.round1 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round2 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round3 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round4 + ' ' + styles.deepColor}></div>
            </div>

            <Dialog
                className={styles.nameDialog}
                visible={showInputDialog}
                title="修改作品名"
                confirmBtn="确定"
                cancelBtn="取消"
                content={<input onChange={handleName} placeholder="请输入作曲名" />}
                destroyOnClose
                onClose={() => {
                    setShowInputDialog(false);
                }}
                onConfirm={() => {
                    if (newName.trim() === '') {
                        Toast.fail({ message: '输入不能为空' })
                        return;
                    }
                    // 检查输入的值是否超过12位
                    if (newName.length > 12) {
                        Toast.fail({ message: '输入不能超过12位' })
                        return;
                    }

                    updateName();

                    setShowInputDialog(false);
                }}
            />

        </div>
    )
}


export default StepTitle