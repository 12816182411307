import styles from './Pay.module.scss'
import React, { useState, useRef, useEffect } from 'react'
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import { Button, Toast, Dialog, Popup } from 'tdesign-mobile-react'
import { useAuth } from '../../hooks'
import { doFetch } from '../../components'
import { isWeiXin, isPC } from '../../components/wxApi'
import { formatDate } from '../../components/format'

// import VConsole from 'vconsole';
// const vConsole = new VConsole();

function Pay() {
    const query = new URLSearchParams(useLocation().search)
    const [confirmProps, setConfirmProps] = useState({ visible: false })
    const [isLoading, setIsLoading] = useState(false)
    const [QRcode, setQRCode] = useState('')
    const [outTradeNo, setOutTradeNo] = useState('')
    const [popVisible, setPopVisible] = useState(false)
    const [type, setType] = useState('oneMonth')
    const [membershipAmount,setMembershipAmount] = useState([])
    

    //gameId弹窗是否显示
    //const [gameDialogVisible,setGameDialogVisible] = useState(false)
    let gameId = '';


    const switchType = (type) => {
        setType(type)
    }

    const { user,
        vipType,
        headImageUrl,
        expiredTime,} = useAuth();
    
    const navigate = useNavigate();

    const goPay = () => {
        setIsLoading(true);

        if (user) {
            //setGameDialogVisible(true)
            createTrades(gameId)
            
        } else {
            setIsLoading(false);
            navigate('/login?uri=' + encodeURIComponent('/pay'));
        }
    }

    const createTrades = async (gameId)=>{
        const response = await doFetch(process.env.REACT_APP_HOST, '/v1/trades', 'POST', {
            //const response = await doFetch("https://txmusic.woa.com", '/v1/trades', 'POST', {
            "membership": {
                "expiredTime": type
            },
            "weixinPay": {
                "type": isPC() ? 'native' : (isWeiXin() ? "jsapi" : "h5"),
            },
            "gameId":gameId
        })
        const respJson = await response.json()
        setIsLoading(false);
        if (respJson.code === 0) {
            setOutTradeNo(respJson.response.weixinPayResponse.outTradeNo)
            if (isWeiXin()) {
                if (typeof window.WeixinJSBridge == 'undefined') {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
                    }
                } else {
                    const jsPayData = respJson.response.weixinPayResponse.jsapiPayData
                    onBridgeReady(
                        jsPayData.timeStamp,
                        jsPayData.nonceStr,
                        jsPayData.package,
                        jsPayData.signType,
                        jsPayData.paySign,
                        respJson.response.weixinPayResponse.outTradeNo,
                    )
                }
            }
            else if (isPC()) {

                setQRCode(respJson.response.weixinPayResponse.nativeQRCodeBase64);
                setPopVisible(true);
            }
            else {

                //setAlertProps({ visible: false })
                openConfirmDialog(respJson.response.weixinPayResponse.outTradeNo)
                window.location.href =
                    respJson.response.weixinPayResponse.h5Url +
                    '&redirect_url=' +
                    encodeURIComponent(process.env.REACT_APP_HOST_FRONT + '/pay?outTradeNo=' + respJson.response.weixinPayResponse.outTradeNo)
            }
        }
    }

    const onBridgeReady = (timeStamp, nonceStr, packageStr, signType, paySign, outTradeNo) => {
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            {
                appId: process.env.REACT_APP_WEIXIN_MP_APPID,
                timeStamp: timeStamp,
                nonceStr: nonceStr,
                package: packageStr,
                signType: signType,
                paySign: paySign,
            },
            function (res) {
                if (res.err_msg === 'get_brand_wcpay_request:ok') {
                    console.log('outTradeNo',outTradeNo)
                    openConfirmDialog(outTradeNo)
                } else {
                    Toast.fail({ message: '请先完成支付' })
                }
            },
        )
    }

    useEffect(() => {
        //如果带支付订单号则出现弹窗
        let tradeNo = query.get('outTradeNo')
        if (tradeNo) {
            openConfirmDialog(tradeNo)
        }

        getPrice();
    }, [])

    const getPrice =async ()=>{
        //获取价格
        const response = await doFetch(process.env.REACT_APP_HOST, '/v1/trades/amount', 'POST', {
            "gameId": ''
        })
        
        const respJson = await response.json()
        
        if (respJson.code === 0) {
            setMembershipAmount(respJson.response.membershipAmount)
        }else if(respJson.code === 401) {
            navigate('/login?uri=' + encodeURIComponent('/pay'));
        }else{
            Toast.fail({ message: '获取价格失败，请重试' })
        }
    }

    const openConfirmDialog = (id) => {
        setConfirmProps({
            visible: true,
            title: '请确认微信支付是否完成',
            confirmBtn: '完成支付',
            cancelBtn: '放弃支付',
            onConfirm: () => {
                getTradeInfo(id)
            },
        })
    }

    const getTradeInfo = async (id) => {
        const response = await doFetch(process.env.REACT_APP_HOST, '/v1/trades/' + id, 'GET', '')
        console.log(response,'response');
        if (response.ok) {
            const respJson = await response.json()
            if (respJson.code === 0) {
                if (respJson.response.state === 'SUCCESS') {
                    Toast.success({ message: '购买成功，请前往XMusic小程序体验全部权益', duration: 5000 })

                    //navigate('/dashboard/market', { replace: true })
                } else {
                    Toast.fail({ message: '请先完成支付' })
                }
            } else {
                Toast.fail({ message: '购买失败' })

            }
        } else {
            Toast.fail({ message: '网络错误' })

        }
        setConfirmProps({ visible: false })
        setPopVisible(false)
    }


    const formatNumber = n => {
        n = n.toString()
        return n[1] ? n : `0${n}`
    }

    const handleGameId = (event)=>{
        gameId = event.target.value
    }

    return (
        <div className={styles.containerPay}>
            <div className={`${styles.head} ${vipType == 2 ? styles.bg2 : styles.bg1}`}>
                <div className={styles.img}>
                    <img className={styles.avatar} src={headImageUrl} />
                </div>
                <div className={styles.right}>
                    <div className={styles.state}>
                        {vipType == 2 ? '你好，尊贵的超级乐迷' : '暂未开通超级乐迷'}
                    </div>
                    <div className={styles.desc}>
                        {vipType == 2 ? '有效期至：' + formatDate(new Date(expiredTime * 1000)) : '开通超级乐迷尊享多重权益'}
                    </div>
                </div>
            </div>
            <div className={styles.title}>开通超级乐迷</div>
            <div className={styles.price}>

                {membershipAmount.map((item, index) => (
                    <div className={`${styles.priceItem} ${type == item.type ? styles.cur : ''}`} key={item.typeCN} onClick={() => switchType(item.type)} data-value={item.type}>
                        <div className={styles.priceHead}></div>
                        <div className={styles.month}>{item.typeCN}</div>
                        <div className={styles.pricePay}><span className={styles.symbol}>￥</span>{item.currentPrice / 100}</div>
                        <div className={styles.marketPrice}>￥{item.originalPrice / 100}</div>
                    </div>
                ))}
                
            </div>
            <Button className={styles.pay} size="large" shape="rectangle" theme="primary" onClick={goPay} loading={isLoading}>确认开通超级乐迷</Button>
            <div className={styles.notes}>本服务购买后即刻生效，一旦购买成功，不支持退款</div>

            <div className={styles.title}>超级乐迷权益</div>
            <div className={styles.equityList}>
                <div className={styles.equityItem}>
                    <img className={styles.img} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/pay/equity1.png" show-menu-by-longpress="{{true}}" />
                    <div className={styles.content}>
                        <span className={styles.text}>创作内容任意下载</span>
                        <span className={styles.text1}>支持MP3、MP4、MIDI格式下载</span>
                    </div>
                </div>
                <div className={styles.equityItem}>
                    <img className={styles.img} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/pay/equity2.png" show-menu-by-longpress="{{true}}" />
                    <div className={styles.content}>
                        <span className={styles.text}>创作音乐的商用权限</span>
                        <span className={styles.text1}>制作权、演出权、广播权、网络传播权</span>
                    </div>

                </div>
                <div className={styles.equityItem}>
                    <img className={styles.img} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/pay/equity3.png" show-menu-by-longpress="{{true}}" />
                    <div className={styles.content}>
                        <span className={styles.text}>专属资源下的极致体验</span>
                        <span className={styles.text1}>更快的创作速度、海量的重试次数、限期内容永久有效</span>
                    </div>
                </div>
            </div>

            <Dialog
                {...confirmProps}
                onCancel={() => {
                    setConfirmProps({ visible: false })
                }}
            />

            {/* <Dialog
                className={styles.gameDialog}
                visible={gameDialogVisible}
                title="请输入作品ID，高赞作品可享8折优惠"
                confirmBtn="确定"
                cancelBtn="放弃"
                content={<input onChange={handleGameId}  placeholder="输入作品ID" />}
                destroyOnClose
                onClose={() => {
                    setGameDialogVisible(false);
                    createTrades('')
                }}
                onConfirm={() => {
                    if (gameId.trim() === '') {
                        Toast.fail({ message: '输入不能为空' })
                        return;
                      }
                      // 检查输入的值是否包含中文
                      if (/[\u4e00-\u9fa5]/.test(gameId)) {
                        Toast.fail({ message: '输入不能包含中文' })
                        return;
                      }
                      // 检查输入的值是否超过12位
                      if (gameId.length > 12) {
                        Toast.fail({ message: '输入不能超过12位' })
                        return;
                      }

                    setGameDialogVisible(false);
                    createTrades(gameId)
                }}
            /> */}

            <Popup visible={popVisible} placement="center">
                <div className={styles.popMain}>
                    <div className={styles.popText}>扫描一下二维码付款</div>
                    <img className={styles.popCode} src={'data:image/Jpeg;base64,' + QRcode}></img>
                    <div className={styles.popBtns}>
                        <div className={styles.popCancel} onClick={() => { setPopVisible(false) }}>放弃支付</div>
                        <div className={styles.popConfirm} onClick={() => { getTradeInfo(outTradeNo) }}>已完成支付</div>
                    </div>
                </div>
            </Popup>
        </div>

    )
}

export default Pay