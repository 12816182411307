import { Routes, Route,Navigate,useLocation } from 'react-router-dom'

import Home from './pages/Home'
// import UserHome from './pages/UserHome'
// import Market from './pages/Market'
// import Profile from './pages/Profile'
import Login from './pages/Login'
// import View from './pages/View'
import Policy from './pages/Policy'
import My from './pages/My/My.jsx'
import Pay from './pages/Pay/Pay.jsx'

import UpdateInfo from './pages/UpdateInfo/UpdateInfo.jsx'
import Player from './pages/Player/Player.jsx'
import Header from './components/Header/Header.js'
import { useAuth } from './hooks'
import Index from './pages/Game/Index/Index.jsx'
import Step1 from './pages/Game/Step1/Step1.jsx'
import Step2 from './pages/Game/Step2/Step2.jsx'
import Step3 from './pages/Game/Step3/Step3.jsx'
import Step4 from './pages/Game/Step4/Step4.jsx'
import Rain from './pages/Game/Rain/Rain.jsx'

function App() {
  const { user } = useAuth()


  const PrivateRoute = ({ children }) => {
    const location = useLocation();

    if (!user) {
      // 用户未登录，重定向到登录页面
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    // 用户已登录，渲染子组件
    return children;
  };

  const aegis = new window.Aegis({
    id: process.env.REACT_APP_AEGIS_ID,
    uin: 'qt',
    reportApiSpeed: true,
    reportAssetSpeed: true,
    spa: true,
  })

  return (
    <Routes>
      <Route path="/music" element={
        <>
          <Header/>
          <Home />
        </>
      } />
      <Route path="login" element={
        <>
          <Header/>
          <Login />
        </>
        } />
      <Route path="policy" element={
        <>
          <Header/>
          <Policy />
        </>
      } />
      <Route path="player" element={
        <>
          <Header/>
          <Player />
        </>
      } />

      <Route path="/" element={
          <Index />
      } />

      <Route path="game/step1" element={
          
          <PrivateRoute>
          <>
            <Header/>
            <Step1 />
          </>
        </PrivateRoute>
      } />
      
      <Route path="game/step2" element={
          <PrivateRoute>
          <>
            <Header/>
            <Step2 />
          </>
        </PrivateRoute>
      } />

      <Route path="game/step3" element={
          <PrivateRoute>
          <>
            <Header/>
            <Step3 />
          </>
        </PrivateRoute>
      } />

      <Route path="game/step4" element={
          <Step4 />
      } />
      
      <Route path="game/rain" element={
          <PrivateRoute>
          <>
            <Header/>
            <Rain />
          </>
        </PrivateRoute>
      } />


      <Route path="my" element={
        <PrivateRoute>
          <>
            <Header/>
            <My />
          </>
        </PrivateRoute>
      } />
      <Route path="pay" element={
        <PrivateRoute>
          <>
            <Header/>
            <Pay />
          </>
        </PrivateRoute>
      } />
      <Route path="updateinfo" element={
        <PrivateRoute>
          <>
            <Header/>
            <UpdateInfo />
          </>
        </PrivateRoute>
        
      } />
      
      
      {/* <Route path="/dashboard" element={<ProtectedLayout />}>
        <Route path="user" element={<UserHome />} />
        <Route path="market" element={<Market />} />
        <Route path="view" element={<View />} />
        <Route path="profile" element={<Profile />} />
      </Route> */}
    </Routes>
  )
}

export default App
