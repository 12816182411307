import styles from './UpdateInfo.module.scss'
import React, { useState, useEffect } from 'react'
import { doFetch } from '../../components'
import { Toast, Button, Upload } from 'tdesign-mobile-react';
import { useAuth } from '../../hooks'
import { cos, getRootPath } from '../../components/initCos'

let cosInstance = null;

function UpdateInfo() {
    const {
        headImageUrl,
        setHeadImageUrl,
        nickName,
        setNickName } = useAuth();

    useEffect(() => {
        cosInstance = cos('head_image');
    }, [])


    const [inputValue, setInputValue] = useState(nickName || '');

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };


    const defaultAvatarUrl = "https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/pay/default.png";
    const [selectedFile, setSelectedFile] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState(headImageUrl ? headImageUrl : defaultAvatarUrl);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
            const isValidSize = file.size / 1024 / 1024 < 5; // 限制文件大小为5MB

            if (isValidType && isValidSize) {
                setSelectedFile(file);
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onloadend = () => {
                    setAvatarUrl(reader.result);
                }
            } else {
                setSelectedFile(null);
                Toast.fail({ message: '请上传JPEG或PNG格式的图片，且文件大小不超过2MB' })
            }
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const submitHandle = () => {

        if (avatarUrl === defaultAvatarUrl) {
            Toast.fail({ message: '您还未选择头像' })
            return false;
        }

        if (inputValue.trim() === '') {
            Toast.fail({ message: '您还未输入昵称' })
            return false;
        }

        setIsLoading(true);
        if (avatarUrl.indexOf(process.env.REACT_APP_COS_BUCKET) > -1) {
            //不需要上传头像
            let url = avatarUrl.replace("https://" + process.env.REACT_APP_COS_BUCKET + ".cos.ap-guangzhou.myqcloud.com/", "");

            updateUser(url);
        } else {
            //需要上传头像
            onUpload();
        }
    }

    function onUpload() {
        let fileName = selectedFile.name;
        let randomNumber = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000;
        fileName = randomNumber.toString() + new Date().getTime() + fileName;

        cosInstance.then(res=>{
            res.uploadFile({
                Bucket: process.env.REACT_APP_COS_BUCKET, /* 填写自己的 bucket，必须字段 */
                Region: 'ap-guangzhou',     /* 存储桶所在地域，必须字段 */
                Key: getRootPath() + '/' + fileName,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
                Body: selectedFile,
                SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
            },function (err, data) {
                if (err) {
                    console.log(err, '失败原因');
                    Toast.fail({ message: '更新失败，请重试' })
                  } else {
                    let url = data.Location.replace(process.env.REACT_APP_COS_BUCKET + ".cos.ap-guangzhou.myqcloud.com/","");
                    updateUser(url);
                  }
            });
        })
    }

    async function updateUser(url) {
        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/users', 'PUT', {
                nickName: inputValue,
                headImageCosPath: url
            })
            let respJson = await res.json()

            setIsLoading(false);
            if (respJson.code === 0) {
                let data = respJson.response;
                setHeadImageUrl(data.headImageUrl);
                setNickName(data.nickName);
                Toast.success({ message: '修改成功' })
            } else {
                Toast.fail({ message: '更新失败，请重试' })
            }

        } catch (err) {
            setIsLoading(false);
            Toast.fail({ message: '更新失败，请重试' })
            return false;
        }

    }

    return (
        <div className={styles.containerInfo}>
            <div className={styles.header}>
                <div className={styles.edit}>
                    <img className={styles.editImg} alt="" src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/camera.png"></img>
                </div>

                <div className={styles.avatarWrapper}>
                    <input type="file" onChange={handleFileChange} multiple={false} className={styles.file} />
                    <img className={styles.avatar} alt="" src={avatarUrl}></img>
                </div>

            </div>

            <div className={styles.nameWrapper}>
                <span className={styles.name}>昵称:</span>
                <input placeholder="请输入昵称" maxLength="12" type="text" value={inputValue} onChange={handleChange} />
            </div>

            <Button size="medium" theme="primary" loading={isLoading} className={styles.writeMusic} onClick={submitHandle}>确定</Button>
        </div>
    )
}

export default UpdateInfo