let _primeMusicMap = {
  Piano: '钢琴',
  ElectronicOrgan: '电子琴',
  Accordion: '手风琴',
  Guitar: '吉他',
  Bass: '贝斯',
  Ukulele: '尤克里里',
  Harp: '竖琴',
  Violin: '小提琴',
  Viola: '中提琴',
  Cello: '大提琴',
  Erhu: '二胡',
  Lute: '琵琶',
  Guzheng: '古筝',
  Hulusi: '葫芦丝',
  Suona: '唢呐',
  Harmonica: '口琴',
  Flute: '长笛',
  Piccolo: '短笛',
  Clarinet: '单簧管',
  Oboe: '双簧管',
  Saxophone: '萨克斯',
  Cornet: '短号',
  Trumpet: '小号',
  FrenchHorn: '圆号',
  Trombone: '长号',
  Tuba: '大号',
  None: '暂无',
}

let _tokenCookieName = 'jwt'
let _tokenExpireTime = 43200
let _captchaId = '2049935322'

export const primeMusicMap = _primeMusicMap
export const tokenCookieName = _tokenCookieName
export const tokenExpireTime = _tokenExpireTime
export const captchaId = _captchaId
