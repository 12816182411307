import React, { useState, useRef, useEffect,useImperativeHandle } from 'react'
import styles from './NewList.module.scss'
import CustomLoading from '../../../../components/CustomLoading/CustomLoading'
import { Toast } from 'tdesign-mobile-react';
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'

import { doFetch } from '../../../../components'

let timeHandle = null;
let timeHandle2 = null;

const NewList = React.forwardRef((props, ref) => {
    const { type } = props;

    const [listJobs, setListJobs] = useState([])
    
    const navigate = useNavigate();

    const getListJobs = async ()=>{
        clearInterval(timeHandle);
        clearInterval(timeHandle2);

        let jobType = (type == 'videoCompose') ? 'videoCompose' :'hummingCompose'

        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs?jobType='+ jobType + '&outputType=all&offset=0&limit=10', 'GET')
            let respJson = await res.json()

            if (respJson.code === 0 && respJson.response && respJson.response.jobs) {
                let isProgress = false;

                respJson.response.jobs.forEach((item, index) => {
                    if (item.state === 1 || item.state === 2) {
                        //前面排队人数为0
                        if (item.waitingJobCount === 0) {
                            
                            let timestamp = parseInt(new Date().getTime() / 1000);

                            let estimatedTime = 90;
                            if( item.jobType == 'hummingCompose'){
                                estimatedTime = 90
                            }else{
                                if(item.createJobInput.videoComposeRequest.videoDuration < 20){
                                    estimatedTime = 60
                                }else{
                                    estimatedTime = item.createJobInput.videoComposeRequest.videoDuration * 1.3
                                }
                            }

                            let percentage = parseInt(((timestamp - item.compositionTime) / estimatedTime) * 100);

                            item.percentage = percentage > 99 ? 99 : percentage;

                            if(item.percentage == 99){
                                timeHandle2 = setInterval(() => {
                                    getListJobs();
                                },5000)

                            }else{
                                isProgress = true;
                            }

                        } else {
                            item.percentage = 0;
                        }
                    }
                })

                setListJobs(respJson.response.jobs)

                if (isProgress) {

                    timeHandle = setInterval(() => {
                        respJson.response.jobs.forEach((item, index) => {
                            if ((item.state === 1 || item.state === 2) && item.waitingJobCount === 0) {

                                let timestamp = parseInt(new Date().getTime() / 1000);

                                let estimatedTime = 90;
                                if( item.jobType == 'hummingCompose'){
                                    estimatedTime = 90
                                }else{
                                    if(item.createJobInput.videoComposeRequest.videoDuration < 20){
                                        estimatedTime = 60
                                    }else{
                                        estimatedTime = 90
                                    }
                                }

                                let percentage = parseInt(((timestamp - item.compositionTime) / estimatedTime) * 100);

                                if (percentage > 99) {
                                    let arr = [...respJson.response.jobs]
                                    arr[index].percentage = 99;
                                    setListJobs(arr);

                                    getListJobs();
                                } else {
                                    let arr = [...respJson.response.jobs]
                                    arr[index].percentage = percentage;
                                    setListJobs(arr);
                                }
                            }
                        })
                    }, 1000)
                }
            } else {
              
            }
      
          } catch (err) {
            
            return false;
          }
    }

    useImperativeHandle(ref, () => ({
        getListJobs
    }))

    useEffect(() => {
        getListJobs();

        return ()=>{
            clearInterval(timeHandle);
            clearInterval(timeHandle2);
        }
    }, [])

    const goInfo = (item) =>{
        let id = item.jobId;

        if (item.state !== 6) {
            navigate('/player?job_id=' + id);
        }
    }

    return (
        <div className={styles.musicList}>
            {listJobs.length > 0 && (
                <>
                    <div className={styles.musicTitle}>最新作曲列表</div>
                    {listJobs.map((item, idx) => (
                        <div className={styles.musicItem} key={idx} onClick={()=>{goInfo(item)}}>

                            <div className={styles.left}>
                                {(item.state == 6 || item.state == 4 || item.state == 5) ? (
                                    <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-tulie.png" />
                                ) : (
                                    <img src={item.coverUrl ? item.coverUrl : 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-loudou.png'} />
                                )}

                                <span className={styles.name}>{item.compositionName}</span>
                            </div>

                            {item.state === 3 && (
                                <div className={styles.right}>
                                    <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-gou.png" />
                                    已完成
                                </div>
                            )}

                            {(item.state === 4 || item.state === 5) && (
                                <div className={styles.right}>
                                    <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-tan.png" />
                                    作曲失败
                                </div>
                            )}

                            {item.state === 6 && (<div className={styles.right + ' ' + styles.noPass}>审核不通过</div>)}

                            {(item.state === 1 || item.state === 2) && (
                                <div className={styles.right}>
                                    {item.waitingJobCount === 0 ? '' : '排队中'} {item.percentage}%
                                </div>
                            )}
                        </div>
                    ))}

                    {listJobs.length === 10 && (
                        <div className={styles.musicText}> {'>'}仅显示最新10首，前往"我的"查看更多 {'<'}</div>
                    )}
                </>
            )}

        </div>
    )
})
export default NewList