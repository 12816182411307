import styles from './My.module.scss'
import { Cell, CellGroup, Toast, Tabs, TabPanel } from 'tdesign-mobile-react';
import BottomNav from '../../components/BottomNav/BottomNav'
import { formatDateTime, formatDate } from '../../components/format'
import { useAuth } from '../../hooks'
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect } from 'react'
import { doFetch } from '../../components'

let totalCount = 0;
let offset = 0;

function My() {
    const navigate = useNavigate();
    const [listJobs, setListJobs] = useState([]);
    const [listGames, setListGames] = useState([]);

    const [tabType, setTabType] = useState("game");
    const [isLoading, setIsLoading] = useState(false);


    const {
        headImageUrl,
        setHeadImageUrl,
        nickName,
        setNickName,
        expiredTime,
        vipType } = useAuth();


    useEffect(() => {
        if (!nickName) {
            navigate('/login')
            return false;
        }

        getListJobs('game')
    }, [])

    const getListJobs = async (type) => {
        console.log(type)

        if (isLoading == true) {
            return false;
        }

        setIsLoading(true)
        try {
            const res = await doFetch(process.env.REACT_APP_HOST, (type == 'game' ? '/v1/game?offset=' : '/v1/jobs?offset=') + offset + '&limit=30', 'GET')

            const respJson = await res.json()
            setIsLoading(false);
            if (respJson.code === 0) {
                if (respJson.response.jobs && respJson.response.jobs.length > 0) {
                    respJson.response.jobs.map((item) => {
                        if (item.createdTime) item.createdTime = formatDateTime(new Date(item.createdTime * 1000))
                    })

                    setListJobs([...listJobs, ...respJson.response.jobs])
                    totalCount = respJson.response.totalCount
                }

                if (respJson.response.games && respJson.response.games.length > 0) {
                    respJson.response.games.map((item) => {
                        if (item.createdTime) item.createdTime = formatDateTime(new Date(item.createdTime * 1000))
                    })

                    setListGames([...listGames, ...respJson.response.games])
                    totalCount = respJson.response.totalCount
                }

            }
        } catch (err) {
            setIsLoading(false);
            Toast.fail({ message: '获取失败，请重试' })
            return false;
        }
    }

    const getMore = () => {
        if ((window.innerHeight + document.documentElement.scrollTop + 100) > document.getElementById('historyList').offsetHeight) {
            let dataList = tabType == 'game' ? listGames : listJobs;

            if (dataList.length < totalCount && isLoading == false) {

                offset = offset + 30
                getListJobs(tabType)
            }
        }
    }

    // 组件挂载时添加滚动监听，卸载时移除
    useEffect(() => {
        window.addEventListener('scroll', getMore);
        return () => window.removeEventListener('scroll', getMore);
    }, [getMore]);

    const toPlayer = (id) => {
        navigate('/player?job_id=' + id)
    }

    const tabChange = (type) => {
        totalCount = 0;
        offset = 0;

        setTabType(type)
        getListJobs(type)
    }

    const toGame = (id)=>{
        navigate('/game/step4?gameId=' + id)
    }

    return (
        <div className={styles.containerMy}>
            <div className={styles.header}>
                <img className={styles.avatar} src={headImageUrl} onClick={() => { navigate('/updateinfo') }} />
                <div className={styles.info}>
                    <div className={styles.merInfo}>
                        <div className={styles.nickName} onClick={() => { navigate('/updateinfo') }}>{nickName}</div>
                        <div className={styles.vip} onClick={() => { navigate('/pay') }}>
                            <span className={styles.vipLogo + ' ' + (vipType == 2 ? '' : 'grey')}>V</span>
                            <span className={styles.vipName}>{vipType == 2 ? '超级乐迷' : '开通超级乐迷'}</span>
                        </div>
                    </div>
                    {(vipType !== 1 && expiredTime > 0) ? (<div className={styles.expiredTime}>{formatDate(new Date(expiredTime * 1000))}{vipType == 2 ? '到期' : '过期'}</div>) : ''}
                </div>
            </div>

            <div className={styles.historyList} id="historyList">
                <div className={styles.tabs}>
                    <span className={tabType === 'game' ? styles.cur : ''} onClick={() => { tabChange('game') }}>互动游戏</span>
                    <span className={tabType === 'music' ? styles.cur : ''} onClick={() => { tabChange('music') }}>能力体验</span>
                </div>

                {tabType === 'music' && <>
                    <CellGroup>
                        {listJobs.map((item, idx) => (
                            <Cell
                                key={idx}
                                title={item.compositionName}
                                description={item.createdTime}
                                image={<img src={item.coverUrl || item.sourceUrl} />}
                                onClick={() => toPlayer(item.jobId)}
                            >
                            </Cell>
                        ))}
                    </CellGroup>
                    {isLoading && <div className={styles.loading}>加载中...</div>}
                </>}

                {tabType === 'game' && <>
                    <div className={styles.list}>
                        {listGames.map((item, idx) => (
                            <div className={styles.listItem} key={idx} onClick={()=>{toGame(item.gameId)}}>
                                <div className={styles.left}>
                                    <img src={item.originalPictureUrl}></img>
                                </div>

                                <div className={styles.right}>
                                    <div className={styles.listTitle}>{item.title + (item.state == 7 ? '（已过期）' : '')}</div>
                                    <div className={styles.listTime}>{item.createdTime}</div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {isLoading && <div className={styles.loading}>加载中...</div>}
                </>}
            </div>

            <BottomNav></BottomNav>

        </div>
    )
}

export default My