import React, { useState, useRef, useEffect } from 'react'
import styles from './TagMusic.module.scss'
import { Button,Toast } from 'tdesign-mobile-react';
import { useNavigate } from 'react-router-dom'
import CustomLoading from '../../../../components/CustomLoading/CustomLoading'
import { doFetch } from '../../../../components'

const TagMusic = () => {
  const [curTag,setCurTag] = useState('pop')
  const [tagData, setTagData] = useState([
    {
        label:'流行',
        style:'pop',
        url:'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/style1.jpg',
        isSelect:true
    },{
        label:'古典',
        style:'classical',
        url:'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/style2.jpg',
        isSelect:false
    },{
        label:'爵士',
        style:'jazz',
        url:'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/style3.jpg',
        isSelect:false
    },{
        label:'国风',
        style:'china',
        url:'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/style4.jpg',
        isSelect:false
    },{
        label:'轻音乐',
        style:'soft',
        url:'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/style5.jpg',
        isSelect:false
    },
  ])
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const switchTag = (index)=>{
    tagData.forEach((item,i)=>{
      if(i == index){
        item.isSelect = true;
        setCurTag(item.style);
      }else{
        item.isSelect = false;
      }
    })

    setTagData([...tagData])
  }

  const submitHandle =async ()=>{
    setIsLoading(true);
    try {
      const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs', 'POST', {
        "jobType": "tagCompose",
        "tagComposeRequest": {
          "musicStyle": curTag
        }
      })
      let respJson = await res.json()

      setIsLoading(false);
      if (respJson.code === 0) {
        Toast.success({ message: '作曲成功' });

        setTimeout(()=>{
          navigate('/player?job_id=' + respJson.response.jobId);
        },2000)
      }
      else if(respJson.code === 401){
        navigate('/login?uri=' + encodeURIComponent('/'));
      }
      else {
        Toast.fail({ message: '作曲失败，请重试' })
      }

    } catch (err) {
      setIsLoading(false);
      Toast.fail({ message: '作曲失败，请重试' })
      return false;
    }
  }

  const TagList = tagData.map((item, index) => (
    <div className={styles.labelItem} onClick={() => switchTag(index)} key={item.style}>
      <div className={styles.itemMain}>
          <img className={styles.itemImg} src={item.url} />
          {item.isSelect && (<div className={styles.itemTick}>
              <img className={styles.tickImg} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-tick.png" />
          </div>)}
      </div>
      <div className={styles.itemName}>{item.label}</div>

    </div>
  ));


  return (
    <div className={styles.container}>
      <div className={styles.titleDesc}>
        <img className={styles.iconBianji} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-biaoqian.png"  />
        选择你喜欢的曲风标签，让AI为你作曲吧
      </div>
      <div className={styles.labels}>
        {TagList}
      </div>

      <Button size="medium" theme="primary" className={styles.writeMusic} onClick={submitHandle}>开始作曲</Button>
      {isLoading && <CustomLoading message="作曲中"></CustomLoading>}
    </div>
  )
}


export default TagMusic