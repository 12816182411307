import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Header.module.scss'

const HeaderBack = ({ pageTitle }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // 检查是否有历史记录可以返回
  const hasHistory = location.key !== 'default';

  // 处理返回按钮的点击事件
  const handleBack = () => {
    if (hasHistory) {
      navigate(-1); // 如果有历史记录，返回上一页
    } else {
      navigate('/'); // 如果没有历史记录，返回首页
    }
  };

  return (
    <div className={styles.Header}>
      {hasHistory ?
       (<img onClick={handleBack} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/player/icon-back.png"  />)
        : (<img onClick={handleBack} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/player/icon-home.png" />)
        }
      <h1>{pageTitle? pageTitle : 'TXMusic'}</h1>
    </div>
  );
};

export default HeaderBack;