
import React, { useState, useEffect,useRef } from 'react'
import styles from './VideoMusic.module.scss'
import { useNavigate } from 'react-router-dom'
import { Button, Toast } from 'tdesign-mobile-react';
import { doFetch } from '../../../../components'
import { cos, getRootPath } from '../../../../components/initCos'
import CustomLoading from '../../../../components/CustomLoading/CustomLoading'
import { AddIcon, CloseIcon } from 'tdesign-icons-react';
import NewList from '../NewList/NewList';

let cosInstance = null;

const VideoMusic = () => {
  // const [videoUrl, setVideoUrl] = useState('');
  // const [videoCoverUrl, setVideoCoverUrl] = useState('');
  const navigate = useNavigate();
  let videoUrl = '';
  let videoCoverUrl ='';
  const newListRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCoverFile, setSelectedCoverFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [duration, setDuration] = useState(0);


  useEffect(() => {
    cosInstance = cos('source');
  }, [])

  const uploadImg = () => {
    let fileName = selectedCoverFile.name;
    let randomNumber = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000;
    fileName = randomNumber.toString() + new Date().getTime() + fileName;

    cosInstance.then(res => {
      res.uploadFile({
        Bucket: process.env.REACT_APP_COS_BUCKET, /* 填写自己的 bucket，必须字段 */
        Region: 'ap-guangzhou',     /* 存储桶所在地域，必须字段 */
        Key: getRootPath() + '/' + fileName,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
        Body: selectedCoverFile,
        SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
      }, function (err, data) {
        if (err) {
          console.log(err, '失败原因');
          setIsLoading(false);
          Toast.fail({ message: '上传失败，请重试' })
        } else {
          videoCoverUrl = data.Location;
          uploadAudio();
          // let url = data.Location.replace(process.env.REACT_APP_COS_BUCKET + ".cos.ap-guangzhou.myqcloud.com/","");
          // updateUser(url);
        }
      });
    })
  }

  const uploadAudio = () => {
    let fileName = selectedFile.name;
    let randomNumber = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000;
    fileName = randomNumber.toString() + new Date().getTime() + fileName;

    cosInstance.then(res => {
      res.uploadFile({
        Bucket: process.env.REACT_APP_COS_BUCKET, /* 填写自己的 bucket，必须字段 */
        Region: 'ap-guangzhou',     /* 存储桶所在地域，必须字段 */
        Key: getRootPath() + '/' + fileName,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
        Body: selectedFile,
        SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
      }, function (err, data) {
        if (err) {
          console.log(err, '失败原因');
          setIsLoading(false);
          Toast.fail({ message: '上传失败，请重试' })
        } else {
          videoUrl = data.Location;
          videoCompose();
        }
      });
    })
  }


  const submitHandle = async () => {
    if (selectedFile === null) {
      Toast.fail({ message: '您还未上传视频' })
      return false;
    }

    setIsLoading(true);

    uploadImg();
  }

  const videoCompose = async () => {
    try {
      const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs', 'POST', {
        "jobType": "videoCompose",
        "videoComposeRequest": {
          "videoCosPath": videoUrl.replace(process.env.REACT_APP_COS_BUCKET + ".cos.ap-guangzhou.myqcloud.com/", ""),
          "videoCoverCosPath": videoCoverUrl.replace(process.env.REACT_APP_COS_BUCKET + ".cos.ap-guangzhou.myqcloud.com/", ""),
          "videoDuration": duration
        }
      })
      let respJson = await res.json()

      setIsLoading(false);
      if (respJson.code === 0) {
        videoUrl = '';
        videoCoverUrl = '';
        setSelectedCoverFile(null);
        setSelectedFile(null);
        setDuration(0)

        Toast.success({ message: '作曲成功' });

        newListRef.current.getListJobs();

      } 
      else if(respJson.code === 401){
        navigate('/login?uri=' + encodeURIComponent('/'));
      }
      else {
        Toast.fail({ message: '作曲失败1，请重试' })
      }

    } catch (err) {
      console.log(err,'err')
      setIsLoading(false);
      Toast.fail({ message: '作曲失败2，请重试' })
      return false;
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setIsLoading(true);
      const isValidType = ['video/mp4'].includes(file.type);
      const isValidSize = file.size / 1024 / 1024 < 200; // 限制文件大小为200MB

      if (isValidType && isValidSize) {
        setSelectedFile(file);
        //获取PreviewUrl
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);

        //获取duration
        const video = document.createElement('video');
        video.currentTime = 0;
        //video.preload = 'metadata';
        // 自动播放存在兼容性问题，设置静音解决自动播放在不同浏览器的兼容性问题
        video.muted = true; 
        video.autoplay = true;
        video.src = url;
        video.oncanplay = () => {
          setDuration(Math.ceil(video.duration));

          //-----------------获取视频封面图---------------------
          // 创建一个 canvas 元素
          const format = 'image/jpeg';
          const canvas = document.createElement('canvas');
          
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          // 在 canvas 上绘制视频的当前帧
          const context = canvas.getContext('2d');
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          //document.body.appendChild(canvas);

          // 将 canvas 上的图像转换为 Blob
          canvas.toBlob((blob) => {
            if (!blob) {
              console.log('Canvas to Blob conversion failed')
              return false;
            }
            // 将 Blob 转换为 File
            const imgFile = new File([blob], `cover.${format.split('/')[1]}`, {
              type: format,
              lastModified: Date.now()
            });
            setSelectedCoverFile(imgFile)
            setIsLoading(false);
          })
        };

        setSelectedFile(file);
      } else {
        setIsLoading(false);
        setSelectedFile(null);
        Toast.fail({ message: '请上传MP4格式的视频，且文件大小不超过50MB' })
      }
    }
  };

  const deleteFile = (e) => {
    videoUrl = '';
    videoCoverUrl = '';
    setSelectedCoverFile(null);
    setSelectedFile(null);
    setDuration(0)
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleDesc}>
        <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-shipin.png" className={styles.iconBianji} />
        添加一段视频，让AI为你作曲吧
      </div>

      <div className={styles.upload}>
        {!selectedFile &&
          <>
            <AddIcon className={styles.addIcon} />
            <input type="file" onChange={handleFileChange} multiple={false} className={styles.file} />
          </>
        }

        {selectedFile &&
          <>
            <CloseIcon className={styles.closeIcon} onClick={deleteFile} />
            <video controls className={styles.video}>
              <source src={previewUrl} type="video/mp4" />
            </video>
          </>
        }
      </div>

      <div className={styles.rule}>视频大小不超过200M</div>
      <Button size="medium" theme="primary" className={styles.writeMusic} onClick={submitHandle}>开始作曲</Button>
      <NewList type="videoCompose" ref={newListRef}></NewList>
      {isLoading && <CustomLoading></CustomLoading>}
    </div>
  )
}


export default VideoMusic