import React, { useState, useEffect } from 'react'
import styles from './TextMusic.module.scss'
import { Textarea, Button, Toast } from 'tdesign-mobile-react';
import { doFetch } from '../../../../components'
import CustomLoading from '../../../../components/CustomLoading/CustomLoading'
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'

const TextMusic = () => {

  const [textValue, setTextValue] = useState('');
  const [labelList, setLabelList] = useState([]);
  const [offsetStart, setOffsetStart] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getLabels();
  }, [])

  //获取labels
  const getLabels = async () => {
    try {
      const res = await doFetch(process.env.REACT_APP_HOST, '/v1/text_library?offset=' + offsetStart + '&limit=' + 5, 'GET')
      let respJson = await res.json()
      if (respJson.code === 0) {
        setLabelList(respJson.response.texts)
        setTotalCount(respJson.response.totalCount)
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const textChange = (event)=>{
    console.log(event);
    // const inputText = event.target.value;
    setTextValue(event);
  }

  const switchText = (text) => {
    setTextValue(text)
  }

  const getMore = () => {
    if ((offsetStart + 5) < totalCount) {
      setOffsetStart(offsetStart + 5)
    } else {
      setOffsetStart(0)
    }
    getLabels();
  }

  

  const submitHandle =async ()=>{
    if (textValue.trim() === '') {
      Toast.fail({ message: '您还未输入文字' })
      return false;
    }
    if (textValue.length > 50) {
      Toast.fail({ message: '输入文字超过50字' })
      return false;
    }

    setIsLoading(true);
    try {
      const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs', 'POST', {
        "jobType": "textCompose",
        "textComposeRequest": {
          "text": textValue
        }
      })
      let respJson = await res.json()

      setIsLoading(false);
      if (respJson.code === 0) {
        setTextValue([]);
        Toast.success({ message: '作曲成功' });

        setTimeout(()=>{
          navigate('/player?job_id=' + respJson.response.jobId);
        },2000)
      }
      else if(respJson.code === 401){
        navigate('/login?uri=' + encodeURIComponent('/'));
      }
      else {
        Toast.fail({ message: '作曲失败，请重试' })
      }

    } catch (err) {
      setIsLoading(false);
      Toast.fail({ message: '作曲失败，请重试' })
      return false;
    }
  }

  return (
    <div className={styles.containerText}>
      <div className={styles.titleDesc}>
        <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-bianji2.png" className={styles.iconBianji} />
        试着写一句话，让AI为你作曲吧
      </div>

      <Textarea autofocus placeholder="仅支持输入中文，最多不超过50字" className={styles.textarea} onChange={textChange} value={textValue} maxLength={50}/>

      <div className={styles.textCount}>
        {textValue.length}/50
      </div>

      <div className={styles.lableTitle} >
        点击文字，立即开始创作：
      </div>

      <div>
        {labelList.map((item) =>
          (<div className={styles.lableText} key={item.text} onClick={() => switchText(item.text)}>{item.text}</div>)
        )}
      </div>

      <div className={styles.more} onClick={getMore}>
        换一批
      </div>

      <Button size="medium" theme="primary" className={styles.writeMusic} onClick={submitHandle} >开始作曲</Button>

      {isLoading && <CustomLoading message="作曲中"></CustomLoading>}
    </div>
  )
}


export default TextMusic