import styles from './Rain.module.scss'

import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect } from 'react'
import { Button, Upload, Popup, Toast } from 'tdesign-mobile-react';
import CustomLoading from '../../../components/CustomLoading/CustomLoading'
import { doFetch } from '../../../components'


let imgBaseUrl = 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step2/'
let timer;
let win = 414;

function Rain() {
    const rainBox = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const [duration, setDuration] = useState(12);
    const durationRef = useRef(12);

    const [secondMask, setSecondMask] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [types, setTypes] = useState(['do', 're', 'mi', 'fa', 'sol', 'la', 'si']);
    const [typesCount, setTypesCount] = useState([0, 0, 0, 0, 0, 0, 0]);
    const typesCountRef = useRef([0, 0, 0, 0, 0, 0, 0]);

    const [liParams, setLiParams] = useState([]);

    const audioRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (sessionStorage.getItem('gameId')) {
            if (rainBox.current) {
                win = rainBox.current.offsetWidth;
            }
        } else {
            navigate('/')
        }

        
    }, [])

    const startHandle = () => {
        if (!isPlaying && !secondMask) {

            setSecondMask(true)

            countDownFn()
        }
    }

    const countDownFn = () => {
        let self = this
        setTimeout(() => {

            setSecondMask(false)

            const randomNumbers = generateRandomNumbers();

            startRain(randomNumbers)
            countDownFn20()
            setIsPlaying(true)

            if (audioRef.current) {
                audioRef.current.play();
              }

        }, 3000)
    }

    //const play = () => {
        
        //this.data.myAudio.play();
    //}

    const startRain = (randomNumbers) => {
        let liParams = [];
        randomNumbers.map((item, index) => {
            let rotate = (parseInt(Math.random() * 90 - 45)) + "deg" // 旋转角度
            let w = (Math.random() * 90) + win / 7; //音符宽度
            //let durTime = parseInt(Math.random() * 1.5) + 2.5 + 's' // 时间
            // let left = parseInt(Math.random() *  (win - w)); //left坐标

            // //left坐标限制
            // if (left < 0) {
            //     left = 0
            // } else if (left > (win - (w*1.25))) {
            //     left = win - (w*1.25)//旋转后宽度不准确，所以乘了个1.25
            // }

            let left = parseInt(Math.random() * (win - 0.5 * (Math.sqrt(2) + 1) * w)); //left坐标

            liParams.push({
                left: left + 'px',
                width: w + 'px',
                height: w + 'px',
                transform: 'rotate(' + rotate + ')',
                isShow: true,
                type: item,
                animationDelay: 12 / 84 * index + 's'
            })
        })

        setLiParams(liParams);
    }

    const tapDom = (index, type) => {
        const updatedData = liParams.map((obj, i) => {
            if (i === index) {
                return { ...obj, isShow: false }; // 修改指定对象的name属性
            }
            return obj;
        });

        setLiParams(updatedData)


        const updatedCount = typesCount.map((obj, i) => {
            if (i === type) {
                return obj + 1;
            }
            return obj;
        });

        typesCountRef.current = updatedCount;
        setTypesCount(updatedCount)

    }

    const countDownFn20 = () => {
        let self = this
        let timer = setInterval(() => {
            if (durationRef.current == 0) {
                clearInterval(timer)

                nextStep()
            } else {
                let duration = durationRef.current - 1

                if (duration < 10) {
                    duration = '0' + duration
                }

                setDuration(duration);
                durationRef.current = duration;
            }
        }, 1000)
    }

    //7种音符，每种最多12个，随机生成84个
    const generateRandomNumbers = () => {
        const numbers = [0, 1, 2, 3, 4, 5, 6];
        const maxOccurrences = 12;
        const totalCount = 84;
        const result = [];

        // 初始化计数器
        const countMap = new Map();
        for (const num of numbers) {
            countMap.set(num, 0);
        }

        // 生成随机数
        for (let i = 0; i < totalCount; i++) {
            let randomIndex;
            let randomNumber;
            do {
                randomIndex = Math.floor(Math.random() * numbers.length);
                randomNumber = numbers[randomIndex];
            } while (countMap.get(randomNumber) >= maxOccurrences);

            result.push(randomNumber);
            countMap.set(randomNumber, countMap.get(randomNumber) + 1);
        }

        return result;
    }

    const nextStep =async ()=>{
        setIsLoading(true);

        const sum = typesCountRef.current.reduce((total, num) => total + num, 0);

        let noteRatio = {}

        types.forEach((v,i)=>{
            noteRatio[v] = parseFloat((typesCountRef.current[i] / sum).toFixed(4));
        })

        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game', 'POST', {
                "action": "getInstrumentMusic",
                "getInstrumentMusicRequest": {
                    "gameId": sessionStorage.getItem('gameId'),
                    "noteRatio": noteRatio
                }
            })
            let respJson = await res.json()

            
            if (respJson.code === 0) {

                sessionStorage.setItem('instrumentMusics', JSON.stringify(respJson.response.instrumentMusics))
                sessionStorage.setItem('noteRatio', JSON.stringify(noteRatio))

                
                setTimeout(() => {
                    setIsLoading(false);
                    navigate('/game/step3')
                }, 2500)
            }
            else if (respJson.code === 401) {
                navigate('/login?uri=' + encodeURIComponent('/'));
            }
            else if (respJson.code === 11024) {
                setIsLoading(false);
                Toast.fail({ message: '本轮游戏已结束' })
                setTimeout(() => {
                    navigate('/')
                    sessionStorage.setItem('gameId', '')
                }, 2500)
            }
            else {
                setIsLoading(false);
                Toast.fail({ message: '制作失败，请重试' })
            }

        } catch (err) {
            console.log(err);
            setIsLoading(false);
            Toast.fail({ message: '制作失败，请重试' })
            return false;
        }

    }


    return (
        <div className={styles.container}>
            <div className={styles.title}>Step3 创作音乐</div>
            <div className={styles.desc}>点击屏幕可捕捉音符来创作音乐</div>
            <div className={styles.step}>
                <div className={styles.stepLine + ' ' + styles.deepColor}></div>
                <div className={styles.stepLine + ' ' + styles.deepColor}></div>
                <div className={styles.stepLine}></div>

                <div className={styles.stepRound + ' ' + styles.round1 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round2 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round3 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round4}></div>
            </div>

            <div className={styles.rainBox} onClick={startHandle}>
                {!isPlaying &&
                    <div className={styles.guide}>
                        <img className={styles.guideImg} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step1/guide-img.png" />
                        <div className={styles.guideTitle}>玩法说明</div>
                        <div className={styles.guideDesc}>音符将随机掉落，点击可收集音符，游戏结束后XMusic</div>
                        <div className={styles.guideDesc}>将根据您收集到的 {sessionStorage.getItem('instrument')} 音符进行创作</div>
                    </div>
                }

                {secondMask &&
                    <div className={styles.countDown}>
                        <div className={styles.number}>3</div>
                        <div className={styles.number}>2</div>
                        <div className={styles.number}>1</div>
                    </div>
                }

                {!secondMask &&
                    <>
                        <div className={styles.redPacket} ref={rainBox}>
                            {liParams.map((item, index) =>
                            (

                                <div key={index}>
                                    {item.isShow &&
                                        <div
                                            className={styles.package}
                                            bindanimationend="removeDom"
                                            onClick={() => { tapDom(index, item.type) }}
                                            style={item}
                                        >
                                            <span style={item}
                                                className={styles[types[item.type]]} ></span>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </>
                }
            </div>

            <div className={styles.time}>
                00:{duration}
            </div>

            <div className={styles.symbol}>
                <div className={styles.symbolList}>
                    {Array(12).fill().map((item, index) => (
                        <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount[0] ? styles.bright : '')} key={index}></div>
                    ))}
                    Do
                </div>
                <div className={styles.symbolList}>
                    {Array(12).fill().map((item, index) => (
                        <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount[1] ? styles.bright : '')} key={index}></div>
                    ))}
                    Re
                </div>
                <div className={styles.symbolList}>
                    {Array(12).fill().map((item, index) => (
                        <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount[2] ? styles.bright : '')} key={index}></div>
                    ))}
                    Mi
                </div>
                <div className={styles.symbolList}>
                    {Array(12).fill().map((item, index) => (
                        <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount[3] ? styles.bright : '')} key={index}></div>
                    ))}
                    Fa
                </div>
                <div className={styles.symbolList}>
                    {Array(12).fill().map((item, index) => (
                        <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount[4] ? styles.bright : '')} key={index}></div>
                    ))}
                    Sol
                </div>
                <div className={styles.symbolList}>
                    {Array(12).fill().map((item, index) => (
                        <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount[5] ? styles.bright : '')} key={index}></div>
                    ))}
                    La
                </div>
                <div className={styles.symbolList}>
                    {Array(12).fill().map((item, index) => (
                        <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount[6] ? styles.bright : '')} key={index}></div>
                    ))}
                    Si
                </div>
            </div>

            {isLoading && <CustomLoading message='正在生成主乐器音乐片段...'></CustomLoading>}

            <audio ref={audioRef} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/musical/bg.mp3" 
            loop/>
        </div>
    )
}

export default Rain