import React, { useState, useRef, useEffect } from 'react'
import styles from './BottomNav.module.scss'
import { BrowserRouter as Router, Switch, useLocation, Link } from 'react-router-dom';

const BottomNav = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    
    if(currentPath === '/'){
      setSelected(0)
    }else if(currentPath === '/music'){
      setSelected(1)
    }else if(currentPath === '/my'){
      setSelected(2)
    }
  },[])


  const navigationData = [
    {
      name: '互动游戏',
      selectImg: 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/bottom-nav/game.png',
      img: 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/bottom-nav/game_white.png',
      path:'/'
    },
    {
      name: '能力体验',
      selectImg: 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/bottom-nav/apps.png',
      img: 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/bottom-nav/apps_white.png',
      path:'/music'
    },
    {
      name: '我的',
      selectImg: 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/bottom-nav/user.png',
      img: 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/bottom-nav/user_white.png',
      path:'/my'
    },
  ];

  const handleNavClick = (index) => {
    setSelected(index);
  };

  return (
    <div className={styles.bottomNav}>
      {navigationData.map((item, index) =>
        <div className={styles.navItem} key={index} onClick={() => handleNavClick(index)}>
          <Link to={item.path}>
            <img src={selected === index ?item.selectImg : item.img}></img>
            <div className={selected === index? styles.curText : styles.text}>{item.name}</div>
          </Link>
        </div>
      )}
    </div>
  )
}

export default BottomNav