import { createContext, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLocalStorage } from './useLocalStorage'
import { doFetch } from '../components'
import { tokenCookieName } from '../config'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const navigate = useNavigate()
  const [user, setUser] = useLocalStorage(tokenCookieName)
  
  
  const [headImageUrl, setHeadImageUrl] = useLocalStorage('headImageUrl')
  const [expiredTime, setExpiredTime] = useLocalStorage('expiredTime')
  const [nickName, setNickName] = useLocalStorage('nickName')
  const [userId, setUserId] = useLocalStorage('userId')
  const [vipType, setVipType] = useLocalStorage('vipType')


  const value = useMemo(() => {
    const login = async (data) => {
      const response = await doFetch(process.env.REACT_APP_HOST, '/login', 'POST', data)

      if (response.ok) {
        const respJson = await response.json()
        if (respJson.code === 0) {
          console.log("useAuth",respJson);

          setUser(respJson.response.token)
          setVipType(respJson.response.membership.state);
          setExpiredTime(respJson.response.membership.expiredTime)
          setNickName(respJson.response.nickName)
          setHeadImageUrl(respJson.response.headImageUrl)
          setUserId(respJson.response.uid)

          //localStorage.setItem("userInfo", JSON.stringify(respJson.response));
          return true
        }
      }
      return false
    }

    const logout = () => {
      setUser(null)
      navigate('/', { replace: true })
    }

    return {
      user,
      vipType,
      headImageUrl,
      setHeadImageUrl,
      expiredTime,
      nickName,
      setNickName,
      userId,
      login,
      logout,
      setVipType
    }
  }, [navigate, setUser, user, setVipType, setHeadImageUrl, setExpiredTime, setNickName, nickName,headImageUrl])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}
