import styles from './Step4.module.scss'
import { doFetch } from '../../../components'

import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Button, Upload, Popup, Toast } from 'tdesign-mobile-react';
import CustomLoading from '../../../components/CustomLoading/CustomLoading'
import { useAuth } from '../../../hooks'

import StepTitle from './components/StepTitle/StepTitle'
import AudioPlayer from '../../../components/AudioPlayer/AudioPlayer'

import copy from 'copy-to-clipboard';

function Step4() {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false);
    const [isSelf, setIsSelf] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLike, setIsLike] = useState(false);
    const [title, setTitle] = useState('');
    const [curNickName, setCurNickName] = useState('');
    const [gameId, setGameId] = useState('');
    const [likeNum, setLikeNum] = useState(0);
    const [originalPictureUrl, setOriginalPictureUrl] = useState('');
    const [musicUrl, setMusicUrl] = useState('');
    const [midiUrl, setMidiUrl] = useState('');
    const [instrumentRank, setInstrumentRank] = useState(0);
    const [instrumentTypeCN, setInstrumentTypeCN] = useState('');
    const [isTip, setIsTip] = useState(true);
    const [gameState, setGameState] = useState(0);
    const [expiredTimeCN, setExpiredTimeCN] = useState('');
    const [expiredTime, setExpiredTime] = useState(0);
    const [qrCodePictureUrl, setQrCodePictureUrl] = useState('');

    const [downloadType, setDownloadType] = useState('mp3');
    const [downPopupVisible, setDownPopupVisible] = useState(false);

    const [sharePopupVisible, setSharePopupVisible] = useState(false);

    const { user,
        headImageUrl,
        userId,
        vipType, setVipType } = useAuth();

    const query = new URLSearchParams(useLocation().search)

    useEffect(() => {
        if (query.get('gameId')) {
            setGameId(query.get('gameId'))
            getJobs(query.get('gameId'));
        } else {
            Toast.fail({ message: '未找到gameId' })
        }
    }, [])


    const getJobs = async (id) => {
        setIsLoading(true);
        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game/' + id, 'GET')
            let respJson = await res.json()

            setIsLoading(false);
            if (respJson.code === 0) {
                if (respJson.response.state == 5 || respJson.response.state == 7 || respJson.response.state == 9) {

                    setTitle(respJson.response.title)
                    setCurNickName(respJson.response.nickname)
                    setOriginalPictureUrl(respJson.response.originalPictureUrl)
                    setIsLike(respJson.response.isLike)
                    setLikeNum(respJson.response.likeNum)
                    setMusicUrl(respJson.response.musicUrl)
                    setMidiUrl(respJson.response.midiUrl)
                    setInstrumentRank(respJson.response.instrumentRank)
                    setInstrumentTypeCN(respJson.response.instrumentTypeCN)
                    setGameState(respJson.response.state)
                    setQrCodePictureUrl(respJson.response.qrCodePictureUrl)


                    if (respJson.response.expiredTime > 0) {
                        const expiredTime = respJson.response.expiredTime;
                        const now = Math.floor(Date.now() / 1000); // 当前时间戳，单位为秒
                        const diff = expiredTime - now; // 时间差，单位为秒
                        const secondsInHour = 3600; // 每小时的秒数
                        const secondsInDay = secondsInHour * 24; // 每天的秒数

                        const days = Math.floor(diff / secondsInDay); // 计算天数
                        const hours = Math.floor((diff % secondsInDay) / secondsInHour); // 计算小时数

                        const expiredTimeCN = `${days} 天 ${hours} 小时`;

                        setExpiredTimeCN(expiredTimeCN);
                        setExpiredTime(respJson.response.expiredTime);
                    }

                    if (respJson.response.uid) {
                        if (userId === respJson.response.uid) {
                            setIsSelf(true);
                        }
                    }
                } else {
                    Toast.fail({ message: '请求失败，请重试' })
                }
            }
            else if (respJson.code === 401) {
                navigate('/login?uri=' + encodeURIComponent('/'));
            }
            else {
                setIsLoading(false);
                Toast.fail({ message: '请求失败，请重试' })
            }
        } catch (err) {
            console.log(err);
            setIsLoading(false);
            Toast.fail({ message: '请求失败，请重试' })
            return false;
        }
    }

    const audioPlay = () => {
        if (gameState === 7) {
            if (vipType == 2) {
                Toast({ message: '作品已过期，请点击下方续期按钮激活作品', duration: 2500 })

                return false;
            } else {
                Toast({ message: '作品已过期，开通超级乐迷后可立即激活该作品', duration: 2500 })

                if (isSelf) {
                    setTimeout(() => {
                        navigate('/pay');
                    }, 2500)
                }

                return false;
            }

        }

        setIsPlaying(true)
    }

    const handlePlayPause = (shouldPlay) => {
        setIsPlaying(shouldPlay);
    };

    const giveALike = async () => {
        let flag = !isLike
        let num = likeNum;

        setIsLike(flag)
        setLikeNum(flag ? num + 1 : num - 1)

        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game', 'POST', {
                "action": "giveALike",
                "giveALikeRequest": {
                    "gameId": gameId,
                }
            })
            let respJson = await res.json()
            if (respJson.code === 0) {
                console.log(respJson);
            } else {
                console.log(respJson);
            }

        } catch (err) {
            console.log(err);
        }
    }

    const showZanToast = () => {
        Toast({ message: '1.单曲超过3个赞即可享受充值8折优惠一次\n2.点赞数将会作为乐手影响力排名依据', duration: 5000 })
    }

    const openDownload = () => {
        console.log(vipType);
        if (vipType == 2) {
            if (gameState === 5 || gameState === 9) {
                setDownPopupVisible(true)
            }
            else if (gameState == 7) {
                Toast({ message: '作品已过期，请点击下方续期按钮激活作品', duration: 3000 })
                return false;
            }

        } else {
            Toast({ message: '您暂无下载权限，开通超级乐迷后可立即下载', duration: 3000 })

            setTimeout(() => {
                navigate('/pay');
            }, 3000)
        }

    }

    //确认选择
    const downloadEnter = async () => {
        let downloadUrl = "";
        if (downloadType === "mp3") {
            downloadUrl = musicUrl
        } else if (downloadType === "midi") {
            downloadUrl = midiUrl
        }

        setIsLoading(true)
        try {
            const response = await fetch(downloadUrl);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = Date.now() + (downloadType === 'mp3' ? '.mp3' : '.midi'); // 下载的文件名
            link.click();

            URL.revokeObjectURL(url);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error('Failed to download MP3:', error);
        }
        setDownPopupVisible(false)
    }

    const goHome = () => {
        navigate('/');
    }

    const goPay = async () => {
        // 会员点击续期按钮后：走续期接口
        if (vipType == 2) {
            setIsLoading(true)
            try {
                const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game', 'POST', {
                    "action": "activate",
                    "gameActivateRequest": {
                        "gameId": gameId,
                    }
                })
                let respJson = await res.json()
                setIsLoading(false)

                if (respJson.code === 0) {
                    setGameState(respJson.response.state)
                    setExpiredTime(respJson.response.expiredTime)
                    setMusicUrl(respJson.response.musicUrl)
                    setMidiUrl(respJson.response.midiUrl)
                }

            } catch (err) {
                setIsLoading(false)
                console.log(err);
            }

        }
        // 非会员点击续期按钮后：1）若作品未过期，直接跳转开通页；2）若作品已过期，先提示3s“作品已过期，开通超级乐迷后可立即激活该作品”，然后跳转开通页
        else {
            storageGameId();
            if (gameState == 7) {
                Toast({ message: '作品已过期，开通超级乐迷后可立即激活该作品', duration: 3000 })

                setTimeout(() => {
                    navigate('/pay');
                }, 3000)
            } else {
                Toast({ message: '开通超级乐迷后，作品永久有效', duration: 3000 })

                setTimeout(() => {
                    navigate('/pay');
                }, 3000)
            }
        }
    }

    //跳支付前记录下当前gameId，如高赞则有优惠；因IOS都在公众号支付，传参链路断裂，所以用接口传递
    const storageGameId = async () => {
        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/trades/game', 'POST', {
                "gameId": gameId,
            })
        } catch (err) {
            console.log(err);
        }
    }

    const copeUrl = () => {
        copy(window.location.href);
        Toast({ message: '链接复制成功，快发送给好友吧', duration: 3000 })
    }

    const downloadImg = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(qrCodePictureUrl);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = Date.now() + '.png'; // 下载的文件名
            link.click();

            URL.revokeObjectURL(url);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error('Failed to download png:', error);
        }
    }

    return (
        <div className={styles.container}>
            {gameId && <>
                

                <StepTitle
                    isSelf={isSelf}
                    title={title}
                    setTitle={setTitle}
                    curNickName={curNickName}
                    setIsLoading={setIsLoading}
                    gameId={gameId}
                ></StepTitle>

                <div className={styles.main} style={{ backgroundImage: `url(${originalPictureUrl})` }}>
                    {isPlaying && <img className={styles.playerPlay} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-stop.png" onClick={() => { setIsPlaying(false) }} />}
                    {!isPlaying && <img className={styles.playerPlay} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-play.png" onClick={audioPlay} />}
                    <div>
                        <AudioPlayer
                            src={musicUrl}
                            onPlayPause={handlePlayPause}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                        ></AudioPlayer>
                    </div>
                    <div className={styles.heart}>
                        <img onClick={giveALike} style={{ display: `${isLike ? '' : 'none'}` }} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-aixin2.png" />
                        <img onClick={giveALike} style={{ display: `${isLike ? 'none' : ''}` }} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-aixin.png" />
                        <div className={styles.num}>
                            {likeNum}
                        </div>
                    </div>
                </div>

                <div className={styles.remind} onClick={showZanToast}>
                    <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/remind.png" />
                    点赞作用？
                </div>

                <div className={styles.btns + ' ' + ((isTip && isSelf) ? '' : styles.hideTip)}>

                    {(isTip && isSelf) && <div className={styles.tip}>
                        恭喜您成为XMusic第 {instrumentRank} 位 {instrumentTypeCN}手，快与好友分享吧
                        <img className={styles.iconClose} onClick={() => { setIsTip(false) }} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-close.png" />
                        <img className={styles.tipBg} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/tip-bg.png" />
                    </div>}

                    <div className={styles.btn + ' ' + styles.share} onClick={() => { setSharePopupVisible(true) }}>
                        <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-share.png" />
                        分享
                    </div>

                    {isSelf && <div className={styles.btn + ' ' + styles.download} onClick={openDownload}>
                        <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-download.png" />
                        下载
                    </div>}
                    {!isSelf && <div className={styles.btn + ' ' + styles.download} onClick={goHome}>
                        <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-music.png" />
                        我也试试
                    </div>}
                </div>

                {isSelf && <>
                    {gameState === 7 && <>
                        <div className={styles.renew}>
                            该作品过期，
                            <span onClick={goPay}>点击续期</span>
                        </div>
                    </>}
                    {gameState !== 7 && <>
                        {expiredTime === 0 && <div className={styles.renew}>本作品已永久有效</div>}
                        {expiredTime > 0 && <div className={styles.renew}>
                            {expiredTimeCN}后作品({gameId}
                            {/* <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-fuzhi.png" bindtap="copyGameId" /> */}
                            )过期，
                            <span onClick={goPay}>点击续期</span>
                        </div>}
                    </>}
                </>}

            </>}

            <Popup visible={downPopupVisible} onVisibleChange={(visible) => { setDownPopupVisible(visible) }} placement="center">
                <div className={styles.popupBlock}>
                    <img className={styles.popupImage} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/popup-img.png" />
                    <div className={styles.popupTitle}>选择下载格式</div>
                    <div className={styles.popupList}>

                        <div className={styles.popupItem} onClick={() => { setDownloadType('mp3') }}>
                            {downloadType === 'mp3' && <img className={styles.iconGou} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/icon-gou.png" />}
                            {downloadType !== 'mp3' && <img className={styles.iconGou} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/icon-gou3.png" />}
                            <img className={styles.iconDownload} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-download2.png" />
                            音频
                        </div>
                        <div className={styles.popupItem} onClick={() => { setDownloadType('midi') }}>
                            {downloadType === 'midi' && <img className={styles.iconGou} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/icon-gou.png" />}
                            {downloadType !== 'midi' && <img className={styles.iconGou} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/icon-gou3.png" />}
                            <img className={styles.iconDownload} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-download3.png" />
                            midi
                        </div>
                    </div>
                    <Button className={styles.popupBtn} theme="primary" onClick={downloadEnter}>确认选择</Button>
                </div>
            </Popup>

            <Popup visible={sharePopupVisible} onVisibleChange={(visible) => { setSharePopupVisible(visible) }} placement="bottom">
                <div className={styles.popupShare}>
                    <img className={styles.shareMain} src={qrCodePictureUrl} />
                    <div className={styles.shareList}>
                        <div className={styles.shareItem} onClick={copeUrl}>
                            <img className={styles.shareImg} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-share1.png" />
                            <div className={styles.shareText}>微信</div>
                        </div>

                        <div className={styles.shareItem} onClick={downloadImg}>
                            <img className={styles.shareImg} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step4/icon-share3.png" />
                            <div className={styles.shareText}>保存</div>
                        </div>
                    </div>
                </div>
            </Popup>

            {isLoading && <CustomLoading></CustomLoading>}
        </div>
    )
}

export default Step4