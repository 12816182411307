import { useState } from 'react'
import cookie from 'react-cookies'

import { tokenExpireTime } from '../config'

export function useLocalStorage(keyName) {
  const value = cookie.load(keyName)
  const [storedValue, setStoredValue] = useState(value)

  const setValue = (newValue) => {
    try {
      if (newValue) {
        cookie.save(keyName, newValue, { path: '/', maxAge: tokenExpireTime })
      } else {
        cookie.remove(keyName, { path: '/' })
      }
    } catch (err) {
      console.error(err)
    }
    setStoredValue(newValue)
  }

  return [storedValue, setValue]
}
