
import { doFetch } from './index'
import COS from 'cos-js-sdk-v5'

let rootPath = '';

export async function cos(type){
    

    try {
        const res = await doFetch(process.env.REACT_APP_HOST, '/v1/cos_temp_key?type='+type, 'GET')

        const respJson = await res.json()

        if(respJson.code == 401){
            window.location.href="/login?uri="+ encodeURIComponent('/')
            return false
        }


        let data = respJson.response;

        if (!data) {
            return console.error('data invalid');
        }
        rootPath = data.rootPath;

        return new COS({
            // 必选参数
            getAuthorization: function (options, callback) {
                callback({
                    TmpSecretId: data.tempSecretId,
                    TmpSecretKey: data.tempSecretKey,
                    // v1.2.0之前版本的 SDK 使用 XCosSecurityToken 而不是 SecurityToken
                    SecurityToken: data.SessionToken,
                    // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                    StartTime: data.startTime || '', // 时间戳，单位秒，如：1580000000
                    ExpiredTime: data.expiredTime || '', // 时间戳，单位秒，如：1580000900
                });
            }
        });

    } catch (err) {
        console.log(err);
        return false;
    }
}


export function getRootPath (){
    return rootPath
}

