import { Buffer } from 'buffer'
import cookie from 'react-cookies'

import { tokenCookieName } from '../config'

let crypto = require('crypto-js')

const doFetch = async (url, path, method, body) => {
  let md5Str
  if (method === 'POST') {
    let md5 = crypto.algo.MD5.create()
    md5.update(JSON.stringify(body), 'utf8')
    let md5Res = md5.finalize()
    md5Str = md5Res.toString(crypto.enc.Hex)
  } else {
    md5Str = ''
  }

  const contentMD5 = Buffer.from(md5Str).toString('base64')
  const dateTime = new Date().toUTCString()
  const signingStr = ['x-date: ' + dateTime, method, 'application/json', 'application/json', contentMD5, path].join(
    '\n',
  )

  let hmac = crypto.algo.HMAC.create(crypto.algo.SHA1, process.env.REACT_APP_API_APPSECRET)
  hmac.update(signingStr, 'utf8')
  let hmacRes = hmac.finalize()
  let hmacStr = hmacRes.toString(crypto.enc.Base64)
  const sign =
    'hmac id="' +
    process.env.REACT_APP_API_APPKEY +
    '", algorithm="hmac-sha1", headers="x-date", signature="' +
    hmacStr +
    '"'
  const token = cookie.load(tokenCookieName)
  const parmas = {
    method: method,
    credentials: 'include',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      //'Content-MD5': contentMD5,
      //'Content-Length': JSON.stringify(body).length,
      //'x-date': dateTime,
      // Authorization: sign,
      'Authorization': ' Bearer ' + token,
    },
  }
  if (method !== 'GET') parmas.body = JSON.stringify(body)

  const response = await fetch(url + path, parmas)
  return response
}

export default doFetch
