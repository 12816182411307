import styles from './Step1.module.scss'
import { Cell, CellGroup, Toast } from 'tdesign-mobile-react';

import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect } from 'react'
import { Button } from 'tdesign-mobile-react';
import CustomLoading from '../../../components/CustomLoading/CustomLoading'
import { doFetch } from '../../../components'

let imgBaseUrl = 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step1/'

function Step1() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [datalist, setDataList] = useState([
        {
            img: imgBaseUrl + 'device1.png',
            name: "钢琴",
            key: "piano"
        }, {
            img: imgBaseUrl + 'device6.png',
            name: "古筝",
            key: "guzheng"
        }, {
            img: imgBaseUrl + 'device5.png',
            name: "吉他",
            key: "guitar"
        }, {
            img: imgBaseUrl + 'device4.png',
            name: "小提琴",
            key: "violin"
        }, {
            img: imgBaseUrl + 'device2.png',
            name: "贝斯",
            key: "bass"
        },
        {
            img: imgBaseUrl + 'device7.png',
            name: "竖琴",
            key: "harp"
        },
        {
            img: imgBaseUrl + 'device13.png',
            name: "手风琴",
            key: "accordion"
        }, {
            img: imgBaseUrl + 'device8.png',
            name: "琵琶",
            key: "pipa"
        },
        {
            img: imgBaseUrl + 'device12.png',
            name: "大号",
            key: "tuba"
        },
        {
            img: imgBaseUrl + 'device10.png',
            name: "萨克斯",
            key: "saxophone"
        },
        {
            img: imgBaseUrl + 'device9.png',
            name: "笛子",
            key: "flute"
        }, {
            img: imgBaseUrl + 'device11.png',
            name: "小号",
            key: "trumpet"
        }
    ]);

    const [curIndex, setCurIndex] = useState(0)
    

    useEffect(() => {

    }, [])

    const nextStep =async ()=>{
        setIsLoading(true);
        try {
          const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game', 'POST', {
            "action": "choiceInstrument",
            "choiceInstrumentRequest": {
                "instrument": datalist[curIndex].key
            }
          })
          let respJson = await res.json()
    
          setIsLoading(false);
          if (respJson.code === 0) {
            console.log(respJson.response.gameId);

            sessionStorage.setItem('instrument', datalist[curIndex].name);
            sessionStorage.setItem('gameId', respJson.response.gameId);

            navigate('/game/step2')
          } 
          else if(respJson.code === 401){
            navigate('/login?uri=' + encodeURIComponent('/'));
          }
          else {
            Toast.fail({ message: '选择失败，请重试' })
          }
    
        } catch (err) {
          setIsLoading(false);
          Toast.fail({ message: '选择失败，请重试' })
          return false;
        }
    }


    return (
        <div className={styles.container}>
            <div className={styles.title}>Step1选择乐器</div>
            <div className={styles.desc} >请选择 1 种主乐器参与音乐创作</div>
            <div className={styles.step} >
                <div className={styles.stepLine} ></div>
                <div className={styles.stepLine} ></div>
                <div className={styles.stepLine} ></div>

                <div className={styles.stepRound + ' ' + styles.round1 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round2}></div>
                <div className={styles.stepRound + ' ' + styles.round3}></div>
                <div className={styles.stepRound + ' ' + styles.round4}></div>
            </div>
            <div>{datalist[0].name}</div>
            <div className={styles.main}>
                {datalist.map((item, idx) => (
                    <div className={styles.item + (curIndex === idx ? ' ' + styles.selected : '')} key={item.key} onClick={()=>{setCurIndex(idx)}}>
                        <img src={item.img} />
                        <div className={styles.itemName}>{item.name}</div>
                    </div>
                ))}
            </div>

            <Button size="medium" theme="primary" className={styles.btn} onClick={nextStep}>确定选择</Button>

            {isLoading && <CustomLoading></CustomLoading>}
        </div>
    )
}

export default Step1