export function isWeiXin() {
  let ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('micromessenger') !== -1
}


export function isPC() {

  var userAgentInfo = navigator.userAgent;

  var Agents = ["Android", "iPhone",

    "SymbianOS", "Windows Phone",

    "iPad", "iPod"];

  var flagPc = true;

  for (var v = 0; v < Agents.length; v++) {

    if (userAgentInfo.indexOf(Agents[v]) > 0) {

      flagPc = false;

      break;

    }

  }

  return flagPc;

}


export function getWxCode(uri, state) {
  if (isWeiXin()) {
    window.location.href =
      process.env.REACT_APP_WEIXIN_OPEN +
      '/connect/oauth2/authorize?' +
      new URLSearchParams({
        appid: process.env.REACT_APP_WEIXIN_MP_APPID,
        redirect_uri: uri,
        response_type: 'code',
        scope: 'snsapi_userinfo',
        state: state,
      }) +
      '#wechat_redirect'
  } else {
    window.location.href =
      process.env.REACT_APP_WEIXIN_OPEN +
      '/connect/qrconnect?' +
      new URLSearchParams({
        appid: process.env.REACT_APP_WEIXIN_APPID,
        redirect_uri: uri,
        response_type: 'code',
        scope: 'snsapi_login',
        state: state,
      }) +
      '#wechat_redirect'
  }
}

export function onBridgeReady(appId, timeStamp, nonceStr, packageStr, signType, paySign) {
  window.WeixinJSBridge.invoke(
    'getBrandWCPayRequest',
    {
      appId: appId,
      timeStamp: timeStamp,
      nonceStr: nonceStr,
      package: packageStr,
      signType: signType,
      paySign: paySign,
    },
    function (res) {
      if (res.err_msg == 'get_brand_wcpay_request:ok') {
        return true
      } else {
        console.log(false)
        return false
      }
    },
  )
}
