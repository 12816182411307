import styles from './Step2.module.scss'

import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect } from 'react'
import { Button, Upload, Popup, Toast } from 'tdesign-mobile-react';
import CustomLoading from '../../../components/CustomLoading/CustomLoading'
import { cos, getRootPath } from '../../../components/initCos'
import { doFetch } from '../../../components'


let imgBaseUrl = 'https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step2/'
let cosInstance = null;

function Step2() {
    const [imgUrl, setImgUrl] = useState('');
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [gender, setGender] = useState('');
    const [sexPopupVisible, setSexPopupVisible] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        if (sessionStorage.getItem('gameId')) {
            cosInstance = cos('source');
        } else {
            navigate('/')
        }
    }, [])

    const uploadObj = (file) => {
        if (files[0].size >= 10000000) {
            Toast.fail({ message: '上传图片不能大于10M!' })

            return false;
        }

        setIsLoading(true)

        return new Promise((resolve, reject) => {
            let fileExtension = (file.raw.name).substring((file.raw.name).lastIndexOf(".") + 1);
            let randomNumber = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000;
            let fileName = randomNumber.toString() + new Date().getTime() + '.' + fileExtension;

            cosInstance.then(res => {
                res.uploadFile(
                    {
                        Bucket: process.env.REACT_APP_COS_BUCKET, /* 填写自己的 bucket，必须字段 */
                        Region: 'ap-guangzhou',     /* 存储桶所在地域，必须字段 */
                        Key: getRootPath() + '/' + fileName,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
                        Body: file.raw,
                        SliceSize: 1024 * 1024 * 5    /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
                    },
                    (err, data) => {
                        setIsLoading(false)
                        if (data) {
                            setImgUrl(data.Location)
                            resolve({
                                status: 'success',
                                response: {
                                    url: 'https://' + data.Location
                                    // name: key,
                                },
                            })
                        } else {
                            resolve({
                                status: 'fail',
                                error: '上传失败',
                            })
                        }
                    },
                )
            })
        })
    }

    const uploadChange = (file) => {
        setFiles(file);
    }

    const onSexPopupVisible = (visible) => {
        setSexPopupVisible(visible)
    }

    const sexEnter = async () => {
        setIsLoading(true);


        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game', 'POST', {
                "action": "generatePicture",
                "generatePictureRequest": {
                    "gameId": sessionStorage.getItem('gameId'),
                    "pictureCosPath": imgUrl.replace(process.env.REACT_APP_COS_BUCKET + ".cos.ap-guangzhou.myqcloud.com/", ""),
                    "gender": gender
                }
            })
            let respJson = await res.json()

            setIsLoading(false);
            if (respJson.code === 0) {
                console.log(respJson.response.gameId);
                Toast({ message: '您的 ' + sessionStorage.getItem('instrument') + ' 定制形象正在后台生成，下面开始音乐创作吧', });

                setSexPopupVisible(false);
                setTimeout(() => {
                    navigate('/game/rain')
                }, 2500)
            }
            else if (respJson.code === 401) {
                navigate('/login?uri=' + encodeURIComponent('/'));
            }
            else if (respJson.code === 11024) {
                Toast.fail({ message: '本轮游戏已结束' })
                setTimeout(() => {
                    navigate('/')
                    sessionStorage.setItem('gameId', '')
                }, 2500)
            }
            else {
                Toast.fail({ message: '制作失败，请重试' })
            }

        } catch (err) {
            setIsLoading(false);
            Toast.fail({ message: '制作失败，请重试' })
            return false;
        }
    }

    const nextStep = () => {
        if (files.length === 0) {
            Toast.fail({ message: '您还未选择图片' })
            return false;
        }

        setSexPopupVisible(true);
    }



    return (
        <div className={styles.container}>
            <div className={styles.title}>Step2制作形象</div>
            <div className={styles.desc} >上传 1 张五官清晰的照片定制乐器形象</div>
            <div className={styles.step} >
                <div className={styles.stepLine} ></div>
                <div className={styles.stepLine} ></div>
                <div className={styles.stepLine} ></div>

                <div className={styles.stepRound + ' ' + styles.round1 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round2}></div>
                <div className={styles.stepRound + ' ' + styles.round3}></div>
                <div className={styles.stepRound + ' ' + styles.round4}></div>
            </div>

            <Upload
                accept="image/*"
                max="1"
                files={files}
                className={styles.Upload}
                requestMethod={uploadObj}
                onChange={uploadChange}
            />

            <div className={styles.exampleTitle}>
                <div className={styles.exampleText}>效果示例</div>
            </div>
            <div className={styles.exampleList}>
                <div className={styles.exampleItem}>
                    <img className={styles.exampleImage} src={imgBaseUrl + "demo1.png"} />
                    <div className={styles.exampleText}>原图</div>
                </div>
                <div className={styles.exampleItem}>
                    <img className={styles.exampleImage} src={imgBaseUrl + "demo1-res.png"} />
                    <div className={styles.exampleText}>效果图</div>
                </div>
                <div className={styles.exampleItem}>
                    <img className={styles.exampleImage} src={imgBaseUrl + "demo2.png"} />
                    <div className={styles.exampleText}>原图</div>
                </div>
                <div className={styles.exampleItem}>
                    <img className={styles.exampleImage} src={imgBaseUrl + "demo2-res.png"} />
                    <div className={styles.exampleText}>效果图</div>
                </div>
            </div>


            <Button size="medium" theme="primary" className={styles.btn} onClick={nextStep}>生成形象</Button>

            <Popup visible={sexPopupVisible} onVisibleChange={onSexPopupVisible} placement="center">
                <div className={styles.popupBlock}>
                    <img className={styles.popupImage} src={imgBaseUrl + "popup-img.png"} />
                    <div className={styles.popupTitle}>请为乐器形象指定性别</div>
                    <div className={styles.popupList}>
                        <div className={styles.popupItem + ' ' + (gender === 'male' ? styles.selected : '')} onClick={() => { setGender('male') }}>
                            <img className={styles.iconDownload} src={imgBaseUrl + "sex1.png"} />
                            <div className={styles.popupText}>男性</div>
                        </div>
                        <div className={styles.popupItem + ' ' + (gender === 'female' ? styles.selected : '')} onClick={() => { setGender('female') }}>
                            <img className={styles.iconDownload} src={imgBaseUrl + "sex2.png"} />
                            <div className={styles.popupText}>女性</div>
                        </div>
                    </div>
                    <Button className={styles.popupBtn} theme="primary" onClick={sexEnter}>确认选择</Button>
                </div>
            </Popup>

            {isLoading && <CustomLoading></CustomLoading>}
        </div>
    )
}

export default Step2