import styles from './Policy.module.css'
import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Service from './service';
import Privacy from './privacy';
import Open from './open';

function Policy() {
  const query = new URLSearchParams(useLocation().search)
  let type = query.get('type');
  let content;

  if (type === 'service') {
    content = <Service />
  } else if (type === 'privacy') {
    content = <Privacy />
  } else if (type === 'open') {
    content = <Open />
  } else {
    content = <div>参数错误</div>;
  }

  return (
    <div className={styles.container}>
      {content}
    </div>
    
      
  )
}

export default Policy
