import React, { useState, useEffect } from 'react'
import styles from './HotMusic.module.scss'
import { Button } from 'tdesign-mobile-react';
import { doFetch } from '../../../../components'
import { useNavigate, useLocation } from 'react-router-dom'


const HotMusic = (props) => {
  const {setType} = props;
  const [imgList, setImgList] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    getLabels();
  }, [])

  //获取labels
  const getLabels = async () => {
    try {
      const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs/exhibition?offset=0&limit=10', 'GET')
      let respJson = await res.json()
      if (respJson.code === 0) {
        setImgList(respJson.response.jobs)
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const goInfo = (id)=>{
    navigate('/player?job_id=' + id);
  }

  return (
    <div className={styles.container}>
      {imgList && imgList.length > 0 &&
        (<>
          <div className={styles.list}>
            <div className={`${styles.column} ${styles.marginR10}`}>
              <div className={`${styles.imgSquare} ${styles.width230} ${styles.marginB10}`} onClick={()=>{goInfo(imgList[0].jobId)}}>
                <img src={imgList[0].sourceUrl} mode="widthFix" />
                <div className={styles.lable}>
                  {imgList[0].compositionName}
                </div>
              </div>
              <div className={`${styles.imgSquare} ${styles.width230}`} onClick={()=>{goInfo(imgList[1].jobId)}}>
                <img src={imgList[1].sourceUrl} mode="widthFix" />
                <div className={styles.lable}>
                  {imgList[1].compositionName}
                </div>
              </div>
            </div>
            <div className={`${styles.column} ${styles.marginR10}`}>
              <div className={`${styles.imgRectangle} ${styles.width160}`} onClick={()=>{goInfo(imgList[2].jobId)}}>
                <img className={styles.aspectFill} src={imgList[2].sourceUrl} mode="aspectFill" />
                <div className={styles.lable}>
                  {imgList[2].compositionName}
                </div>
              </div>
            </div>
            <div className={`${styles.column}`}>
              <div className={`${styles.imgSquare2} ${styles.marginB10}`} onClick={()=>{goInfo(imgList[3].jobId)}}>
                <img src={imgList[3].sourceUrl} mode="widthFix" />
                <div className={styles.lable}>
                  {imgList[3].compositionName}
                </div>
              </div>
              <div className={`${styles.imgSquare2}`} onClick={()=>{goInfo(imgList[4].jobId)}}>
                <img src={imgList[4].sourceUrl} mode="widthFix" />
                <div className={styles.lable}>
                  {imgList[4].compositionName}
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.list} ${styles.height405}`}>
            <div className={`${styles.column} ${styles.marginR16}`}>
              <div className={`${styles.imgSquare} ${styles.width300} ${styles.marginB10} ${styles.height197}`} onClick={()=>{goInfo(imgList[5].jobId)}}>
                <img src={imgList[5].sourceUrl} mode="widthFix" />
                <div className={styles.lable}>
                  {imgList[5].compositionName}
                </div>
              </div>
              <div className={`${styles.imgSquare} ${styles.width300}`} onClick={()=>{goInfo(imgList[6].jobId)}}>
                <img src={imgList[6].sourceUrl} mode="widthFix" />
                <div className={styles.lable}>
                  {imgList[6].compositionName}
                </div>
              </div>
            </div>
            <div className={`${styles.column} ${styles.marginR16}`}>
              <div className={`${styles.imgRectangle} ${styles.width193}`} onClick={()=>{goInfo(imgList[7].jobId)}}>
                <img className={styles.aspectFill} src={imgList[7].sourceUrl} mode="aspectFill" />
                <div className={styles.lable}>
                  {imgList[7].compositionName}
                </div>
              </div>
            </div>
            <div className={styles.column}>
              <div className={`${styles.imgRectangle} ${styles.width193}`} onClick={()=>{goInfo(imgList[8].jobId)}}>
                <img className={styles.aspectFill} src={imgList[8].sourceUrl} mode="aspectFill" />
                <div className={styles.lable}>
                  {imgList[8].compositionName}
                </div>
              </div>
            </div>
          </div>
        </>)}


      <Button size="medium" theme="primary" className={styles.writeMusic} onClick={()=>{setType('img')}}>去创作</Button>
      <div className={styles.textDesc}>©Tencent Media Lab</div>
    </div>
  )
}


export default HotMusic