import React, { useState, useRef, useEffect } from 'react'
import styles from './RecordMusic.module.scss'
import ReactEcharts from 'echarts-for-react';
import Recorder from 'recorder-core';
import 'recorder-core/src/engine/wav';
import CustomLoading from '../../../../components/CustomLoading/CustomLoading'
import { Button, Toast } from 'tdesign-mobile-react';
import { cos, getRootPath } from '../../../../components/initCos'
import { doFetch } from '../../../../components'
import { useNavigate } from 'react-router-dom'
import NewList from '../NewList/NewList';

let chartInterval = null;
let timerInterval = null;
let rec;

let seconds = 0;
let cosInstance = null;

const RecordMusic = () => {
  const navigate = useNavigate();
  const newListRef = useRef(null);

  const [recorderState, setRecorderState] = useState(0) //0初始界面  1 录音中   2录音已结束  4试听录音
  const [reckonTime, setReckonTime] = useState('00:00')
  const [audioBlob, setAudioBlob] = useState(null);
  const audioRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hummingDuration,setHummingDuration] = useState(0);

  const [yData, setYData] = useState([4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4])
  let xData = [];

  for (let i = 1; i < yData.length; i++) {
    xData.push(i);
  }

  const chartOptions = {
    grid: {
      left: '1%',
      right: '1%',
      bottom: '0%'
    },
    xAxis: {
      type: 'category',
      data: xData,
      show: false
    },
    yAxis: {
      type: 'value',
      show: false,
      min: 0,
      max: 255,
    },
    series: [
      {
        data: yData,
        type: 'bar',
        itemStyle: {
          normal: {
            borderRadius: 0,
            color: '#00C885'
          }
        },
      }
    ]
  };

  //录音时间达到十秒强制结束录音
  useEffect(() => {
    if (recorderState === 1 && seconds === 10) {
      stopRecording();
    }
  }, [recorderState, seconds]);

  const recOpen = (success) => {
    rec = Recorder({
      type: "wav",
      sampleRate: 44100,
      bitRate: 16
    })
    rec.open(function () {//打开麦克风授权获得相关资源
      success && success();
    }, function (msg, isUserNotAllow) {//用户拒绝未授权或不支持
      Toast.fail({ message: '获取麦克风失败！' })
      console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg);
    });
  }

  const recStop = () => {
    rec.stop(function (blob, duration) {
      //简单利用URL生成本地文件地址，注意不用了时需要revokeObjectURL，否则霸占内存
      //此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
      setAudioBlob(blob);
      setHummingDuration(Math.ceil(duration/1000));

      rec.close();//释放录音资源，当然可以不释放，后面可以连续调用start；但不释放时系统或浏览器会一直提示在录音，最佳操作是录完就close掉
      rec = null;

    }, function (msg) {
      console.log("录音失败:" + msg);
      rec.close();//可以通过stop方法的第3个参数来自动调用close
      rec = null;
    });
  };

  //开始录音
  const startRecording = () => {
    recOpen(() => {
      rec.start();
      setRecorderState(1)

      chartAnimation();
    });
  }

  //结束录音/试听
  const stopRecording = () => {
    //停止录音
    if (recorderState === 1) {
      recStop();
    }
    //停止试听
    else if (recorderState === 4) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setRecorderState(2)
    clearInterval(chartInterval);
    clearInterval(timerInterval);
    setYData([4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4])
  }

  //播放录音
  const playRecording = () => {
    setRecorderState(4)
    chartAnimation();
    const audioUrl = URL.createObjectURL(audioBlob);
    audioRef.current.src = audioUrl;
    audioRef.current.play();

  }

  //试听播放完毕
  const handleAudioEnd = () => {
    console.log('录音播放完毕');
    // 这里可以执行播放结束后的操作
    stopRecording();
  };

  const chartAnimation = () => {
    chartInterval = setInterval(() => {
      // 生成随机数据
      const newData = yData.map(() => Math.floor(Math.random() * 255));
      setYData(newData);
    }, 300);

    seconds = 0;
    setReckonTime('00:00')

    timerInterval = setInterval(() => {
      seconds++;
      setReckonTime('00:' + (seconds < 10 ? '0' + seconds : seconds))
    }, 1000)
  }

  useEffect(() => {
    cosInstance = cos('source');
  }, [])

  //上传录音
  const uploadRecord = () => {
    setIsLoading(true);
    let randomNumber = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000;
    let fileName = randomNumber.toString() + new Date().getTime() + '.wav';

    let file = new File([audioBlob], fileName, { type: audioBlob.type });

    cosInstance.then(res => {
      res.uploadFile({
        Bucket: process.env.REACT_APP_COS_BUCKET, /* 填写自己的 bucket，必须字段 */
        Region: 'ap-guangzhou',     /* 存储桶所在地域，必须字段 */
        Key: getRootPath() + '/' + fileName,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
        Body: file,
        SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
      }, function (err, data) {
        if (err) {
          console.log(err, '失败原因');
          setIsLoading(false);
          Toast.fail({ message: '上传失败，请重试' })
        } else {
          
          videoCompose(data.Location);
        }
      });
    })
  }

  const videoCompose = async (url) => {
    try {
      const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs', 'POST', {
        "jobType": "hummingCompose",
        "hummingComposeRequest": {
          "hummingCosPath": url.replace(process.env.REACT_APP_COS_BUCKET + ".cos.ap-guangzhou.myqcloud.com/", ""),
          "hummingDuration": hummingDuration
        }
      })
      let respJson = await res.json()

      setIsLoading(false);
      if (respJson.code === 0) {

        setRecorderState(0)
        setHummingDuration(0);
        seconds = 0;
        setReckonTime('00:00')
        setAudioBlob(null)

        Toast.success({ message: '作曲成功' });
        newListRef.current.getListJobs();

      } 
      else if(respJson.code === 401){
        navigate('/login?uri=' + encodeURIComponent('/'));
      }
      else {
        Toast.fail({ message: '作曲失败，请重试' })
      }

    } catch (err) {
      setIsLoading(false);
      Toast.fail({ message: '作曲失败，请重试' })
      return false;
    }
  }
  


  return (
    <div className={styles.recordMusic}>
      <div className={styles.titleDesc}>
        <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-huatong.png" className={styles.iconBianji} />
        试着哼唱一小段，让AI为你作曲吧
      </div>
      <div className={styles.block}>
        <ReactEcharts option={chartOptions} className={styles.chart} />
      </div>
      <div className={styles.rule}>
        哼唱时长不超过10秒
      </div>
      <div className={styles.reckonTime}>
        {reckonTime}
      </div>
      <div className={styles.btns}>
        {(recorderState === 0 || recorderState === 2) && (<div className={styles.btnItem} onClick={startRecording}>
          <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-luyin.png" />
          {recorderState === 0 && (<div className={styles.btnText}>点击录音</div>)}
          {recorderState === 2 && (<div className={styles.btnText}>重新录音</div>)}
        </div>)}
        {(recorderState === 1 || recorderState === 4) && (<div className={styles.btnItem} onClick={stopRecording}>
          <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-jieshu.png" />
          <div className={styles.btnText}>结束</div>
        </div>)}
        {recorderState === 2 && (<div className={styles.btnTtem} onClick={playRecording}>
          <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-lubo.png" />
          <div className={styles.btnText}>播放录音</div>
        </div>)}
        {recorderState === 2 && (<div className={styles.btnTtem} onClick={uploadRecord}>
          <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-luzuo.png" />
          <div className={styles.btnText}>开始作曲</div>
        </div>)}
      </div>
      <NewList type="hummingCompose" ref={newListRef}></NewList>
      <audio
        ref={audioRef}
        onEnded={handleAudioEnd}
      />
      {isLoading && <CustomLoading></CustomLoading>}
    </div>
  )
}
export default RecordMusic