import React from 'react';

const Privacy = () => {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>隐私政策</h3>
      概要<br></br>
      我们严格遵守法律法规，遵循隐私保护原则，为您提供更加安全、可靠的服务。<br></br><br></br>
      TXMusic是由深圳市腾讯计算机系统有限公司（以下简称“我们”）提供的产品，我们的注册地为深圳市南山区粤海街道麻岭社区科技中一路腾讯大厦35层。<br></br>
      您可以通过阅读本隐私政策详细了解我们对个人信息的收集和处理方式，以便您更好地了解我们的服务并作出适当的选择。同时，我们对可能收集的您的敏感信息及相关重点内容，已采用粗体特别提示，希望您在阅读时特别关注。<br></br><br></br>
      您同意本隐私政策表示您已经了解并同意在相关场景下，为实现功能运行，我们将对您的相关个人信息进行处理。但这并不代表只要您开始使用我们的产品与/或服务，我们将处理本隐私政策中涉及的全部个人信息。仅当您使用特定功能时，我们才会根据最小必要原则，为实现向您提供服务的目的，处理您的相关个人信息。<br></br><br></br>

      1. 个人信息的收集和使用<br></br>
      2. 信息的存储<br></br>
      3. 信息安全<br></br>
      4. 第三方信息处理及公开披露<br></br>
      5. 您的权利<br></br>
      6. 变更<br></br>
      7. 未成年人保护<br></br>
      8. 联系我们<br></br><br></br>

      1. 个人信息的收集和使用<br></br>
      TXMusic是由深圳市腾讯计算机系统有限公司（以下简称“我们”）提供服务的智能作曲产品。在您使用产品服务的过程中，我们会遵循合法、正当、最小必要、公开透明的原则，按照如下方式收集您在使用服务时主动提供的或因为使用服务而产生的信息，用以向您提供服务。<br></br>
      1.1个人信息收集使用说明<br></br>
      1.1.1 当您进入TXMusic时，我们会收集您的微信open ID、union ID，为您创建TXMusic游客帐号。为落实网络实名制要求，后续我们可能会收集您的手机号，若您拒绝提供的，我们将无法向您提供服务。<br></br>
      1.1.2 当您进入TXMusic时，我们会收集您的设备型号，用于识别您的设备，保障您正常使用我们的服务。<br></br>
      1.1.3 当您使用TXMusic时，您可以授权我们获取您的微信头像、昵称，您的名称及帐号头像将展示您的微信昵称、头像；若您拒绝授权，您的名称、帐号头像将展示系统默认值（如微信用户），但不影响其他功能的正常使用。同时我们还会收集您输入的文本\图片\视频\音频信息，以便用于自动生成音乐。<br></br>
      1.1.4 当您使用音频作曲功能时，您需要开启麦克风权限。如您不开启相应权限，将无法使用。<br></br>
      当您使用该功能，我们将收集您的音频数据，以用于智能作曲的提示词。收集数据是为了实现该功能所必需。该功能目的实现后，未经您同意，我们不会在服务器中留存您的音视频数据。<br></br>
      1.1.5 当您的TXMusic帐号出现异常时，为符合相关法律法规要求，打击电信网络诈骗，保障您和其他用户的安全，维护功能和系统服务的安全稳定，您可以联系客服申请处理。<br></br>
      1.2 权限申请使用说明<br></br>
      为向您提供完善、优质的服务，TXMusic会申请和使用下列权限获取相关信息。您可以在微信小程序内中对相关权限进行管理，从而限制小程序获取相关信息。<br></br>
      权限名称	调用目的	管理方式<br></br>
      麦克风权限	哼唱作曲	进入小程序>点击右上角“…”>“设置”>“麦克风”进行管理<br></br>
      相册权限	图片/视频作曲	进入小程序>点击右上角“…”>“设置”>“相册”进行管理<br></br>
      当您使用我们的小程序产品时，只有在您触发相关业务功能时，我们才会向您申请相关权限，并获取相应信息（如涉及）。请您放心，我们申请访问的权限只限于实现特定的功能所必需，并且您可以根据系统及小程序平台提供的设置撤回权限的授权。如您拒绝或撤回授权权限，会使您无法使用相应功能，但并不影响您正常使用产品与/或服务的其他功能。<br></br>
      1.3为记录登录凭证用于鉴权、简化您重复操作的步骤、便于您查看使用历史，我们会使用Cookie同类技术localStorage在设备本地存储您的用户信息、登录凭证、历史作曲信息[如您的微信open ID、union ID、设备型号、微信头像（如有）、微信昵称（如有）]。我们不会将Cookie同类技术用于本隐私政策所述目的之外的任何用途。如果您拒绝我们使用Cookie同类技术收集和使用您的相关信息，您可以通过本隐私政策提供的联系方式联系我们，或删除已经储存在您的移动设备或其他装置内的Cookie同类技术。<br></br>
      1.4我们会在本隐私政策所涵盖的用途内使用收集的信息。如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。<br></br>
      1.5根据相关法律法规的规定，我们在以下情况下收集和使用您的个人信息无需取得您的授权同意：
      （1）为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；<br></br>
      （2）为履行法定职责或者法定义务所必需；<br></br>
      （3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br></br>
      （4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理您的个人信息；<br></br>
      （5）依照法律规定在合理范围内处理您自行公开或者其他已经合法公开的个人信息；<br></br>
      （6）法律、行政法规规定的其他情形。<br></br>
      1.6请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。<br></br><br></br>

      2. 信息的存储<br></br>
      2.1 信息存储的方式和期限<br></br>
      一般情况下，我们仅在为实现目的所必需的最短时间内保留您的个人信息，但下列情况除外：<br></br>
      ·为遵守适用的法律法规等有关规定；<br></br>
      ·为遵守法院判决、裁定或其他法律程序的规定；<br></br>
      ·为遵守相关政府机关执法的要求。<br></br>
      当我们的产品或服务发生停止运营的情形时，我们将采取例如推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息，但法律法规另有规定的除外。<br></br>
      2.2 信息存储的地域<br></br>
      我们会按照法律法规规定，将中华人民共和国境内收集的用户个人信息存储于中华人民共和国境内。目前我们暂时不存在向境外提供您的个人信息的场景。<br></br><br></br>

      3. 信息安全<br></br>
      3.1 安全保护措施<br></br>
      我们为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或披露。<br></br>
      · 我们使用不低于行业同行安全水平的安全保护措施以保障信息的安全。例如，我们使用加密技术、匿名化处理等手段来保护您的个人信息。<br></br>
      · 我们建立专门的管理制度、流程和组织确保信息安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审查。<br></br>
      3.2 安全事件处置措施<br></br>
      为防止安全事件的发生，我们已按照法律法规的规定，制定了妥善的预警机制和应急预案。若确发生个人信息安全事件，除非我们采取措施能够有效避免信息泄露、篡改、丢失等危害时，我们将及时以推送通知、邮件等形式告知您安全事件基本情况及应对建议等相关内容。如果难以实现逐一告知，我们将通过公告等方式发布警示。同时我们将及时启动应急预案，阻止安全事件扩大，遵照法律法规要求进行处理。<br></br><br></br>

      4. 第三方信息处理及公开披露<br></br>
      4.1 共享<br></br>
      我们仅会出于合法、正当、必要且本隐私政策所明确的目的与第三方分享您的个人信息，并且只会分享必要的个人信息。如果第三方改变或超越您原同意范围处理您的个人信息，我们会要求第三方再次征得您的同意。<br></br>
      4.2 公开<br></br>
      我们不会对外公开披露所收集的个人信息，如必须公开披露时，我们会按照法律法规要求，向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的单独同意。<br></br>
      4.3 转让<br></br>
      随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，我们将告知您相关情形，按照法律法规及不低于本隐私政策所要求的标准继续保护或要求新的个人信息处理者继续保护您的个人信息。<br></br>
      4.4 共享、转让、公开披露个人信息授权同意的例外情形<br></br>
      根据相关法律法规的规定，以下情形下，我们共享、转让、公开披露您的个人信息，无需事先取得您的同意：<br></br>
      （1）为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；<br></br>
      （2）为履行法定职责或者法定义务所必需；<br></br>
      （3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br></br>
      （4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；<br></br>
      （5）依照法律规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；<br></br>
      （6）法律、行政法规规定的其他情形。<br></br><br></br>

      5. 您的权利<br></br>
      我们非常重视并保障您个人信息相关权利。<br></br>
      5.1在您使用本小程序期间，如您需要查询、更正您的个人信息，您可以通过本隐私政策“8.联系我们”中提供的联系方式提出您的请求，我们将在接到您的请求之日起十五（15）日内或法律法规规定的期限内予以反馈。<br></br>
5.2如果您需撤回权限授权同意，您可以通过“进入小程序>点击右上角“…”>“设置”>“麦克风”路径，关闭相关权限授权。请您注意，当您撤回前述同意后，我们将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。<br></br>
      因“个人信息与权限使用记录”内数据由您自行管理，您可以按照以下路径关闭您的所有授权：1) 进入小程序后，点击右上角“•••”；2) 点击“设置”；3) 在“允许xx使用”项下点击“xx信息”；4）点击进入后，将所有选项设置为“不允许”。<br></br>
      5.3如果您认为我们违反法律、行政法规的规定或者我们与您的约定处理了您的个人信息，您可以通过本隐私政策“8.联系我们”中提供的联系方式提出删除相关个人信息请求，我们将在接到您的请求之日起十五（15）日内或法律法规规定的期限内予以反馈。<br></br>
      5.4如您需要注销小程序游客帐号，您可以通过本隐私政策“8.联系我们”中提供的联系方式提出注销小程序游客帐号请求，我们将在接到您的请求之日起十五（15）日内或法律法规规定的期限内予以反馈。在您注销帐号之后，我们将停止为您提供产品或服务，并对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。<br></br>
      5.5为保障安全，如您根据本隐私政策向我们提出请求，我们将先验证您的个人信息主体身份，然后再处理您的请求。<br></br><br></br>

      6. 变更<br></br>
      我们可能会适时对本隐私政策进行修订。当本隐私政策的条款发生变更时，我们会在版本更新时向您提示变更后的隐私政策，并向您说明生效日期。请您仔细阅读变更后的隐私政策内容，您继续使用TXMusic表示您同意我们按照更新后的隐私政策指引处理您的个人信息。<br></br><br></br>

      7. 未成年人保护<br></br>
      我们的产品和服务主要面向成年人，但我们非常重视对未成年人个人信息的保护，严格按照相关法律规定处理未成年人个人信息。<br></br>
      根据相关法律法规的规定，若您是已满14周岁的未成年人，在使用我们的服务前，应你的监护人（如您的父母）监护、指导下共同阅读并同意本隐私政策。<br></br>
      特别地，若您是14周岁以下的儿童，在使用本小程序前，您应在征得您的监护人同意，并且确认同意本隐私政策、产品具体的隐私保护指引（如有）和《https://privacy.qq.com/policy/kids-privacypolicy》后向我们提供您的个人信息。<br></br>
      若您是儿童的监护人，请您关注您监护的儿童是否是在取得您的授权同意之后使用我们的服务。当您对您所监护的儿童的个人信息保护有相关疑问或权利请求时，请通过《https://privacy.qq.com/policy/kids-privacypolicy》中披露的联系方式与我们联系。我们会在合理的时间内处理并回复您。<br></br>
      我们仅在法律法规允许、未成年人的监护人明确同意或者有必要保护未成年人的情况下使用、对外提供或公开披露该信息。如果我们发现我们超出上述范围收集了未成年人的个人信息，则会设法尽快删除相关数据。<br></br><br></br>

      8. 联系我们<br></br>
      我们设立了专门的个人信息保护团队和个人信息保护负责人，如果您对本隐私政策或个人信息保护相关事宜有任何疑问或投诉、建议时，您可以通过以下方式与我们联系：<br></br>
      （1）您可以填写 https://privacy.qq.com/questionnaire与我们联系；<br></br>
      （2）将问题发送至Dataprivacy@tencent.com；<br></br>
      （3）通过https://kf.qq.com/与我们联系；<br></br>
      （4.）将您的问题邮寄至下列地址：<br></br>
      中国广东省深圳市南山区海天二路33号腾讯滨海大厦 数据隐私保护部（收）<br></br>
      邮编：518054<br></br>
      我们将尽快审核所涉问题，并在十五（15）日内或法律法规规定的期限内予以反馈。<br></br>
    </div>
  );
};

export default Privacy;