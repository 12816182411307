import React, { useState, useEffect } from 'react'
import styles from './ImgMusic.module.scss'
import { Upload, Button, Toast } from 'tdesign-mobile-react';
import { doFetch } from '../../../../components'
import { cos, getRootPath } from '../../../../components/initCos'
import CustomLoading from '../../../../components/CustomLoading/CustomLoading'
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'


let cosInstance = null;

const ImgMusic = () => {
  const [imgUrl, setImgUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    cosInstance = cos('source');
  }, [])

  const uploadChange = (file) => {
    setFiles(file);
  }

  const uploadObj = (file) => {
    setIsLoading(true)

    return new Promise((resolve, reject) => {
      let fileExtension = (file.raw.name).substring((file.raw.name).lastIndexOf(".") + 1);
      let randomNumber = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000;
      let fileName = randomNumber.toString() + new Date().getTime() + '.' + fileExtension;

      cosInstance.then(res => {
        res.uploadFile(
          {
            Bucket: process.env.REACT_APP_COS_BUCKET, /* 填写自己的 bucket，必须字段 */
            Region: 'ap-guangzhou',     /* 存储桶所在地域，必须字段 */
            Key: getRootPath() + '/' + fileName,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
            Body: file.raw,
            SliceSize: 1024 * 1024 * 5    /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
          },
          (err, data) => {
            setIsLoading(false)
            if (data) {
              setImgUrl(data.Location)
              resolve({
                status: 'success',
                response: {
                  url: 'https://' + data.Location
                  // name: key,
                },
              })
            } else {
              resolve({
                status: 'fail',
                error: '上传失败',
              })
            }
          },
        )
      })
    })
  }

  const submitHandle = async () => {
    if (imgUrl === '') {
      Toast.fail({ message: '您还未上传图片' })
      return false;
    }
    setIsLoading(true);
    try {
      const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs', 'POST', {
        "jobType": "imageCompose",
        "imageComposeRequest": {
          "imageCosPath": imgUrl.replace(process.env.REACT_APP_COS_BUCKET + ".cos.ap-guangzhou.myqcloud.com/", "")
        }
      })
      let respJson = await res.json()

      setIsLoading(false);
      if (respJson.code === 0) {
        setFiles([]);
        Toast.success({ message: '作曲成功' });

        setTimeout(()=>{
          navigate('/player?job_id=' + respJson.response.jobId);
        },2000)
      } 
      else if(respJson.code === 401){
        navigate('/login?uri=' + encodeURIComponent('/'));
      }
      else {
        Toast.fail({ message: '作曲失败，请重试' })
      }

    } catch (err) {
      setIsLoading(false);
      Toast.fail({ message: '作曲失败，请重试' })
      return false;
    }
  }


  return (
    <div className={styles.container}>
      <div className={styles.titleDesc}>
        <img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-tupian.png" className={styles.iconBianji} />
        上传一张图片，让AI为你作曲吧
      </div>
      <Upload
        accept="image/*"
        max="1"
        files={files}
        className={styles.Upload}
        requestMethod={uploadObj}
        onChange={uploadChange}
      />
      <Button size="medium" theme="primary" className={styles.writeMusic} onClick={submitHandle} >开始作曲</Button>

      {isLoading && <CustomLoading></CustomLoading>}
    </div>
  )
}


export default ImgMusic