import React, { useState, useRef, useEffect } from 'react'
import styles from './BtnList.module.scss'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Toast, Dialog } from 'tdesign-mobile-react'
import copy from 'copy-to-clipboard';
import { useNavigate, useLocation } from 'react-router-dom'

const BtnList = (props) => {
    const { isSelf, reComposeCount, vipType, jobType, outputVideoUrl, outputMusicUrl,setIsLoading,reCompose } = props;
    const [downloadDialog, setDownloadDialog] = useState(false)
    const [reComposeDialog, setReComposeDialog] = useState(false)
    const [downloadDialogText, setDownloadDialogText] = useState('')
    const navigate = useNavigate()

    const downloadHandle = () => {
        let downloadDialogText = vipType === 2 ? '尊敬的超级乐迷，您可以任意下载创作内容，本平台授予您该音乐的商用权限' : '成为超级乐迷,任意下载创作内容'
        setDownloadDialogText(downloadDialogText)
        setDownloadDialog(true)
    }

    const shareHandle = () => {
        copy(window.location.href);
        Toast({ message: '链接复制成功，快发送给好友吧', duration: 3000 })
    }

    const goHome = ()=>{
        navigate('/music'); 
    }

    return (
        <>
            <div className={styles.btnList}>
                <div className={styles.btn} onClick={shareHandle}>
                    <img className={styles.img} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-weixin.png"></img>
                    <div className={styles.btnText}>分享</div>
                </div>

                {isSelf &&
                    <div className={styles.btn} onClick={downloadHandle}>
                        <img className={styles.img} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-xiazai.png"></img>
                        <div className={styles.btnText}>下载</div>
                    </div>
                }

                {(reComposeCount > 0 && isSelf) &&
                    <div className={styles.btn} onClick={() => { setReComposeDialog(true) }}>
                        <img className={styles.img} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-zhongzhi.png"></img>
                        <div className={styles.btnText}>重新生成</div>
                    </div>
                }

                {!isSelf &&
                    <div className={styles.btn} onClick={goHome}>
                        <img className={styles.img} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-jia.png"></img>
                        <div className={styles.btnText}>我也要试试</div>
                    </div>
                }
            </div>
            <Dialog
                // className={styles.nameDialog}
                visible={reComposeDialog}
                confirmBtn="确定"
                cancelBtn="取消"
                title={"确定重新生成，剩余" + reComposeCount + "次机会"}
                destroyOnClose
                onClose={() => {
                    setReComposeDialog(false);
                }}
                onConfirm={() => {
                    reCompose()
                    setReComposeDialog(false);
                }}
            />

            <Dialog
                // className={styles.nameDialog}
                visible={downloadDialog}
                confirmBtn="确定"
                cancelBtn="取消"
                title={downloadDialogText}
                destroyOnClose
                onClose={() => {
                    setDownloadDialog(false);
                }}
                onConfirm={async () => {
                    if (vipType === 2) {
                        setIsLoading(true)
                        let downloadUrl = jobType === 'videoCompose' ? outputVideoUrl : outputMusicUrl

                        try {
                            const response = await fetch(downloadUrl);
                            const blob = await response.blob();
                            const url = URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = Date.now() + (jobType === 'videoCompose' ? '.mp4' : '.mp3'); // 下载的文件名
                            link.click();

                            URL.revokeObjectURL(url);
                            setIsLoading(false)
                        } catch (error) {
                            setIsLoading(false)
                            console.error('Failed to download MP3:', error);
                        }

                    } else {
                        navigate('/pay');
                    }
                    setDownloadDialog(false);
                }}
            />
        </>
    )
}


export default BtnList