import React, { useState, useRef, useEffect } from 'react'
import styles from './MusicTitle.module.scss'
import { formatDate } from '../../../../components/format'
import { doFetch } from '../../../../components'
import { Dialog, Toast } from 'tdesign-mobile-react';
import { useNavigate, useLocation } from 'react-router-dom'

const MusicTitle = (props) => {
    const navigate = useNavigate()
    const { compositionName, createdTime, createJobInput, jobId, setIsLoading,setCompositionName,isSelf } = props;

    const [showInputDialog, setShowInputDialog] = useState(false)

    let newName = '';
    const handleName = (event) => {
        newName = event.target.value
    }

    const updateName = async () => {
        setIsLoading(true);
        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs', 'PUT',
                {
                    "jobId": jobId,
                    "compositionName": newName
                }
            )
            let respJson = await res.json()
            console.log(respJson);
      
            setIsLoading(false);
            if (respJson.code === 0) {
                setCompositionName(newName)
                Toast.success({ message: '修改成功' })
            }
            else if (respJson.code === 401) {
              navigate('/login?uri=' + encodeURIComponent('/player?job_id='+jobId));
            }
            else {
              setIsLoading(false);
              Toast.fail({ message: '修改失败，请重试' })
            }
          } catch (err) {
            setIsLoading(false);
            Toast.fail({ message: '修改失败，请重试' })
            return false;
          }
    }

    return (
        <div>
            <div className={styles.mainTitle}>
                {compositionName}
                {isSelf && <img className={styles.iconText} onClick={()=>{setShowInputDialog(true)}} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-bianji.png"></img>}
                {/* <img className={styles.iconText} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-text2.png" ></img> */}
            </div>

            <div className={styles.subTitle}>
                创作于{formatDate(new Date(createdTime * 1000))}
            </div>

            {createJobInput && createJobInput.textComposeRequest && createJobInput.textComposeRequest.text &&
                <div className={styles.desc}>
                    {createJobInput.textComposeRequest.text}
                </div>
            }

            <Dialog
                className={styles.nameDialog}
                visible={showInputDialog}
                title="修改作品名"
                confirmBtn="确定"
                cancelBtn="取消"
                content={<input onChange={handleName} placeholder="请输入作曲名" />}
                destroyOnClose
                onClose={() => {
                    setShowInputDialog(false);
                }}
                onConfirm={() => {
                    if (newName.trim() === '') {
                        Toast.fail({ message: '输入不能为空' })
                        return;
                    }
                    // 检查输入的值是否超过12位
                    if (newName.length > 12) {
                        Toast.fail({ message: '输入不能超过12位' })
                        return;
                    }

                    updateName();
                    
                    setShowInputDialog(false);
                }}
            />

        </div>
    )
}


export default MusicTitle