import React, { useState, useEffect } from 'react'
import styles from './Creating.module.scss'
import { Button } from 'tdesign-mobile-react';
import { doFetch } from '../../../../components'
import { useNavigate, useLocation } from 'react-router-dom'
import { formatDate } from '../../../../components/format'

let timeHandle = null;

const Creating = (props) => {
    const { compositionName,createdTime,coverUrl,compositionTime,createJobInput,contentAuditMessage,waitingJobCount,state,jobType,duration,getJobs,jobId } = props;
    const [percentage, setPercentage] = useState(0);
    const [estimatedTime, setEstimatedTime] = useState(0);

    const navigate = useNavigate()

    useEffect(() => {
        init();

        return ()=>{
            clearInterval(timeHandle);
        }
    }, [])

    const init = ()=> {
        clearInterval(timeHandle)

        let timestamp;
        let percentage;

        if ((state === 1 || state === 2)) {


            if (waitingJobCount === 0) {
                timestamp = parseInt(new Date().getTime() / 1000);

                let estimatedTime = 90;
                if(jobType == 'hummingCompose'){
                    estimatedTime = 90;
                }else{
                    if(duration<20){
                        estimatedTime = 60;
                    }else{
                        estimatedTime = Math.ceil(duration * 1.3);
                    }
                }

                percentage = ((timestamp - compositionTime) / estimatedTime) * 100


                setEstimatedTime(estimatedTime)
                setPercentage(parseInt(percentage))

                if (percentage < 99) {
                    //进度小于99%进度条开始动
                    timeHandle = setInterval(() => {
                        timestamp = parseInt(new Date().getTime() / 1000);
                        percentage = ((timestamp - compositionTime) / estimatedTime) * 100

                        //超过99%请求接口
                        if (percentage > 99) {

                            setPercentage(99)

                            clearInterval(timeHandle)
                            
                            getJobs(jobId);

                        } else {
                            setPercentage(parseInt(percentage))
                        }
                    }, 1000)

                } else {
                    setPercentage(99)

                    timeHandle = setInterval(() => {
                        getJobs(jobId);
                    },5000)
                }
            } else {
                timeHandle = setInterval(() => {
                    getJobs(jobId);
                },5000)
            }

        }
    }

    return (
        <div className={styles.creating}>
            <div className={styles.cover}>
                <img src={coverUrl} mode="widthFix" className={styles.coverImg}></img>

                <div className={styles.coverMark}>
                    {(state === 4 || state === 5 || state === 6) && (
                        <div className={styles.coverState} >
                            <img className={styles.coverIcon} mode="widthFix" src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-bukaixin.png"></img>
                            {(state === 4 || state === 5) && (
                                <span className={styles.coverText}>制作失败</span>
                            )}
                            {state === 6 && (
                                <span className={styles.coverText}>审核不通过</span>
                            )}
                        </div>
                    )}

                    {(state === 1 || state === 2) && (
                        <div className={styles.coverState}>
                            <img className={styles.coverIcon} mode="widthFix" src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-loudou.png"></img>
                            <span className={styles.coverText}>AI作曲中...</span>
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.mainTitle}>
                {compositionName}
            </div>

            <div className={styles.subTitle}>
                创作于{formatDate(new Date(createdTime * 1000))}
            </div>

            <div className={styles.loading}>
                <div className={styles.loadingCur} style={{width: percentage + '%'}}></div>
            </div>

            {(state === 2 || state === 1) && (
                <div className={styles.percentage}>{percentage}%</div>
            )}

            {(state === 2 || state === 1 || state === 6) && (
                <>
                    {(state === 2 || state === 1) && (
                        <div className={styles.text}>

                            {waitingJobCount > 0 ? (
                                <>
                                    <div>AI作曲排队中</div>
                                    <div>目前正排在第{waitingJobCount + 1}名</div>
                                    <div>前面共有有{waitingJobCount}个任务</div>
                                </>

                            ) : (
                                <>
                                    <div>AI正在努力为你制作音乐</div>
                                    <div>预计需要{estimatedTime}秒钟</div>
                                </>
                            )}
                        </div>
                    )}

                    {(state === 6) && (
                        <div className={styles.text}>
                            <div>啊哦~视频审核不通过</div>
                            <div>换一个试试</div>
                        </div>
                    )}

                    <t-button theme="primary" className={styles.writeMusic} bindtap="back">返回</t-button>
                </>
            )}

            {(state === 4 || state === 5) && (
                <>
                    <div className="text">
                        <div>啊哦~AI开小岔了，任务失败</div>
                        {/* <div>再试一次吧</div> */}
                    </div>
                    {/* <t-button theme="primary" className="write-music" bindtap="reCompose">点击重试</t-button> */}
                </>
            )}

        </div>
    )
}


export default Creating