import React, { useState, useRef, useEffect } from 'react';
import styles from './AudioPlayer.module.scss'
import { formatTime } from '../format'

const AudioPlayer = ({ src, onPlayPause, isPlaying, setIsPlaying }) => {
    //const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    // 设置音频对象
    useEffect(() => {
        audioRef.current = new Audio(src);
        const audio = audioRef.current;

        setCurrentTime(0);
        onPlayPause(false)

        // 加载音频元数据后，设置音频时长
        const setAudioData = () => {
            setDuration(audio.duration);
        };

        // 更新当前时间
        const setAudioTime = () => {
            setCurrentTime(audio.currentTime);
        };

        const handleAudioEnd = ()=>{
            setIsPlaying(false);
        };

        audio.addEventListener('loadedmetadata', setAudioData);
        audio.addEventListener('timeupdate', setAudioTime);
        audio.addEventListener('ended', handleAudioEnd);

        // 清理函数
        return () => {
            audio.removeEventListener('loadedmetadata', setAudioData);
            audio.removeEventListener('timeupdate', setAudioTime);
            audio.removeEventListener('ended', handleAudioEnd);
            audio.pause();
        };
    }, [src]);

    // 控制播放和暂停
    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    // 处理进度条拖拽
    const handleProgressChange = (e) => {
        const audio = audioRef.current;
        const time = (audio.duration / 100) * e.target.value;
        audio.currentTime = time;
        setCurrentTime(time);
    };

    return (
        <div className={styles.musicPlay}>
            {/* <button onClick={() => onPlayPause(!isPlayingInternal)}>
                {isPlayingInternal ? 'Pause' : 'Play'}
            </button> */}
            <span>{formatTime(currentTime)}</span>
            <input
                type="range"
                value={duration ? (currentTime / duration) * 100 : 0}
                onChange={handleProgressChange}
                className={styles.slider}
            />
            <span>{formatTime(duration)}</span>
        </div>
    );
};

export default AudioPlayer;