import styles from './Index.module.scss'
import { Cell, CellGroup, Toast } from 'tdesign-mobile-react';
import BottomNav from '../../../components/BottomNav/BottomNav'
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect } from 'react'
import { Button } from 'tdesign-mobile-react';
import AudioPlayer from '../../../components/AudioPlayer/AudioPlayer'

function Index() {
    const navigate = useNavigate();

    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = (shouldPlay) => {
        setIsPlaying(shouldPlay);
      };

    useEffect(() => {

    }, [])


    return (
        <div className={styles.container}>
            <div className={styles.player}>
                <div className={styles.title}>TXMusic</div>
                <div className={styles.playerMain}>
                    {/* <audio-player 
                        id="myAudio" bind:changePlay="changePlay">
                    </audio-player> */}
                    <div className={styles.musicPlay}>
                        <AudioPlayer
                            src='https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/music1.mp3'
                            onPlayPause={handlePlayPause}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                            ></AudioPlayer>
                    </div>
                    
                    <div className={styles.playerTitle} >Urban Relax</div>
                    <div className={styles.playerDesc}>Lorna 创作于 XMusic</div>

                    <div className={styles.playerOperate}>
                        {isPlaying && <img className={styles.playerPlay + ' ' + styles.playerPlay2} onClick={()=>{handlePlayPause(false)}} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/stop.png" />}
                        {!isPlaying && <img className={styles.playerPlay}  onClick={()=>{handlePlayPause(true)}} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/play.png" />}
                    </div>
                </div>
            </div>

            <div className={styles.exampleTitle}>
                <div className={styles.exampleText}>只需4步即可获得专属音乐作品</div>
            </div>

            <div className={styles.steps}>
                <div className={styles.stepsItem}>
                    <img className={styles.stepsImg} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/step1.png"></img>

                    <img className={styles.stepsNum} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/step1-num.png"></img>

                    <div className={styles.stepsText}>选择乐器</div>
                </div>
                <div className={styles.stepsItem}>
                    <img className={styles.stepsImg} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/step2.png"></img>
                    <img className={styles.stepsNum} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/step2-num.png"></img>

                    <div className={styles.stepsText}>制作形象</div>
                </div>
                <div className={styles.stepsItem}>
                    <img className={styles.stepsImg} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/step3.png"></img>
                    <img className={styles.stepsNum} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/step3-num.png"></img>

                    <div className={styles.stepsText}>创作音乐</div>
                </div>
                <div className={styles.stepsItem}>
                    <img className={styles.stepsImg} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/step4.png"></img>
                    <img className={styles.stepsNum} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/step4-num.png"></img>

                    <div className={styles.stepsText}>完成作品</div>
                </div>
            </div>

            <Button size="medium" theme="primary" className={styles.btn} onClick={()=>{navigate('/game/step1')}}>PLAY</Button>

            <BottomNav></BottomNav>

        </div>
    )
}

export default Index