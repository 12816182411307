import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Toast } from 'tdesign-mobile-react'

import styles from './Player.module.css'
import CustomLoading from '../../components/CustomLoading/CustomLoading'

import Cover from './components/Cover/Cover'
import MusicTitle from './components/MusicTitle/MusicTitle'
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer'
import Creating from './components/Creating/Creating'

// Import login
import { useAuth } from '../../hooks'

import { isWeiXin } from '../../components/wxApi'
import { doFetch } from '../../components'
import BtnList from './components/BtnList/BtnList'


const Player = () => {
  const { login } = useAuth()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false)
  const [jobId, setJobId] = useState('')
  const [state, setState] = useState('');
  const [jobType, setJobType] = useState('');
  const [outputMusicUrl, setOutputMusicUrl] = useState('');
  const [outputVideoUrl, setOutputVideoUrl] = useState('');
  const [sourceUrl, setSourceUrl] = useState('');
  const [compositionName, setCompositionName] = useState('');
  const [createdTime, setCreatedTime] = useState('');
  const [compositionTime, setCompositionTime] = useState('');
  const [reComposeCount, setReComposeCount] = useState(0);
  const [createJobInput, setCreateJobInput] = useState('');
  const [coverUrl, setCoverUrl] = useState('');
  const [contentAuditMessage, setContentAuditMessage] = useState('');
  const [waitingJobCount, setWaitingJobCount] = useState(0);
  const [isSelf, setIsSelf] = useState(false);


  const { user,
    headImageUrl,
    expiredTime,
    userId,
    vipType, setVipType } = useAuth();

  const query = new URLSearchParams(useLocation().search)
  

  useEffect(() => {
    let jobId = query.get('job_id');

    if (jobId) {
      getJobs()
      setJobId(jobId)
    } else {
      Toast.fail({ message: '未找到作品ID' })
    }
  }, [])

  const getJobs = async () => {
    let jobId = query.get('job_id');

    setIsLoading(true);
    let timer = null;

    try {
      const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs/' + jobId, 'GET')
      let respJson = await res.json()

      setIsLoading(false);
      if (respJson.code === 0) {
        setState(respJson.response.state)
        setJobType(respJson.response.jobType)
        setOutputMusicUrl(respJson.response.outputMusicUrl || '')
        setOutputVideoUrl(respJson.response.outputVideoUrl || '')
        setSourceUrl(respJson.response.sourceUrl)
        setCompositionName(respJson.response.compositionName)
        setCreatedTime(respJson.response.createdTime)
        setCompositionTime(respJson.response.compositionTime)
        setReComposeCount(respJson.response.musicRegenNumLimit)
        setCreateJobInput(respJson.response.createJobInput || {})
        setCoverUrl(respJson.response.coverUrl || '')
        setContentAuditMessage(respJson.response.contentAuditMessage || '')
        setWaitingJobCount(respJson.response.waitingJobCount || 0)

        if (respJson.response.user && respJson.response.user.uid) {
          if (userId === respJson.response.user.uid) {
            setIsSelf(true);
          }
        }

        //3 作曲完成
        if (respJson.response.state === 3) {
          setIsLoading(false);
          clearTimeout(timer); //清理定时任务

          if (respJson.response.user) {
            setVipType(respJson.response.user.membership.state)
          }
        }

        //4 5 作曲失败
        else if (respJson.response.state === 4 || respJson.response.state === 5) {
          setIsLoading(false);
          if (respJson.response.jobType != 'videoCompose' && respJson.response.jobType != 'hummingCompose') {
            Toast.fail({ message: '生成失败5，请重试' })
            clearTimeout(timer); //清理定时任务
          }
        }
        //4 5 审核不通过
        else if (respJson.response.state === 6) {
          setIsLoading(false);
          if (respJson.response.jobType != 'videoCompose' && respJson.response.jobType != 'hummingCompose') {
            setIsLoading(false);
            Toast.fail({ message: respJson.response.contentAuditMessage ? '审核不通过:' + respJson.response.contentAuditMessage : '审核不通过，换一个试试' })
            clearTimeout(timer); //清理定时任务
          }
        }
        //1 2 生成中
        else if (respJson.response.state === 1 || respJson.response.state === 2) {
          //非视频、哼唱作曲继续轮询
          if (respJson.response.jobType != 'videoCompose' && respJson.response.jobType != 'hummingCompose') {
            timer = setTimeout(() => {
              getJobs()
            }, 1500);
          } else {
            setIsLoading(false);
          }
        }
      }
      else if (respJson.code === 401) {
        navigate('/login?uri=' + encodeURIComponent('/'));
      }
      else {
        setIsLoading(false);
        Toast.fail({ message: '生成失败，请重试' })
      }

    } catch (err) {
      console.log(err);
      setIsLoading(false);
      Toast.fail({ message: '生成失败，请重试' })
      return false;
    }
  }

  const handlePlayPause = (shouldPlay) => {
    setIsPlaying(shouldPlay);
  };

  const reCompose = async () => {

    setIsLoading(true);
    try {
      const res = await doFetch(process.env.REACT_APP_HOST, '/v1/jobs/music_regen', 'POST', {
        "jobId": jobId,
      })
      setIsLoading(false);
      let respJson = await res.json()
      if (respJson.code === 0) {
        Toast.success({ message: '重新生成成功' });
        setState(respJson.response.state)
        setJobType(respJson.response.jobType)
        setOutputMusicUrl(respJson.response.outputMusicUrl || '')
        setOutputVideoUrl(respJson.response.outputVideoUrl || '')
        setSourceUrl(respJson.response.sourceUrl)
        setCompositionName(respJson.response.compositionName)
        setCreatedTime(respJson.response.createdTime)
        setCompositionTime(respJson.response.compositionTime)
        setReComposeCount(respJson.response.musicRegenNumLimit)
        setCreateJobInput(respJson.response.createJobInput || {})
        setCoverUrl(respJson.response.coverUrl || '')
        setContentAuditMessage(respJson.response.contentAuditMessage || '')
        setWaitingJobCount(respJson.response.waitingJobCount || '')
      } else {
        Toast.fail({ message: '生成失败3，请重试' })
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      Toast.fail({ message: '生成失败4，请重试' })
      return false;
    }
  }

  return (
    <div className={styles.container}>
      {((jobType === 'videoCompose' || jobType === 'hummingCompose') && (state === 1 || state === 2 || state === 4 || state === 5 || state === 6)) ? (
        <>
          <Creating
            compositionName={compositionName}
            createdTime={createdTime}
            coverUrl={coverUrl}
            compositionTime={compositionTime}
            createJobInput={createJobInput}
            contentAuditMessage={contentAuditMessage}
            waitingJobCount={waitingJobCount}
            state={state}
            jobType={jobType}
            duration={jobType ==='videoCompose' ? createJobInput.videoComposeRequest.videoDuration : createJobInput.hummingComposeRequest.hummingDuration}
            jobId={jobId}
            getJobs={getJobs}
          >
          </Creating>
        </>
      ) : (
        <>
          {
            (jobType === 'textCompose' || jobType === 'imageCompose' || jobType === 'exhibition' || jobType === 'hummingCompose' || jobType === 'tagCompose') &&
            <Cover
              onPlayPause={handlePlayPause}
              isPlaying={isPlaying}
              sourceUrl={coverUrl ? coverUrl : sourceUrl}
            ></Cover>
          }

          {jobType === 'videoCompose' && (
            <video controls className={styles.videoTag} src={outputVideoUrl}>
              Your browser does not support the video tag.
            </video>
          )}

          {compositionName &&
            <MusicTitle
              compositionName={compositionName}
              createdTime={createdTime}
              isSelf={isSelf}
              jobId={jobId}
              createJobInput={createJobInput}
              setIsLoading={setIsLoading}
              setCompositionName={setCompositionName}
            ></MusicTitle>}

          {outputMusicUrl &&
            <AudioPlayer
              src={outputMusicUrl}
              onPlayPause={handlePlayPause}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            ></AudioPlayer>}

          {(state === 3) &&
            <BtnList
              isSelf={isSelf}
              reComposeCount={reComposeCount}
              vipType={vipType}
              jobType={jobType}
              outputVideoUrl={outputVideoUrl}
              outputMusicUrl={outputMusicUrl}
              setIsLoading={setIsLoading}
              reCompose={reCompose}
            ></BtnList>
          }
        </>

      )}





      {isLoading && <CustomLoading></CustomLoading>}
    </div>
  )
}


export default Player
