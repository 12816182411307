import React, { useState, useRef, useEffect } from 'react'

import { Loading, Popup } from 'tdesign-mobile-react';

import styles from './CustomLoading.module.scss';

const CustomLoading = (props) => {  
  const { message } = props;
  const [visible, setVisible] = useState(true)
  
  const handleVisibleChange = (visible) => {
    setVisible(visible);
  }

  return (
    <Popup visible={visible}  placement="center" className={styles.CustomLoading}>
      <Loading text={message ? message : ''} layout="vertical" />
    </Popup>
  )
}

export default CustomLoading