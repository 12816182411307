import React from 'react'
import styles from './Cover.module.scss'


const Cover = (props) => {
  const { isPlaying,sourceUrl,onPlayPause } = props;
  return (
    <div className={styles.map}>
        <div className={isPlaying ? styles.musicRotate : styles.musicRotate+' '+styles.musicPaused}>
            <img src={sourceUrl} className={styles.img} mode="aspectFill"></img>
        </div>
        
        <div className={styles.playbox}>
            {isPlaying && <img onClick={() => onPlayPause(false)} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/player/pause.png" className={styles.play} ></img>}
            {!isPlaying && <img onClick={() => onPlayPause(true)} src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/player/playCircle.png" className={styles.play} ></img>}
        </div>

    </div>
  )
}


export default Cover