import React from 'react';

const Service = () => {
  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>腾讯服务协议</h2>
      <p>●首部及导言</p>
      欢迎您使用TXMusic（智能作曲）软件及服务！<br></br>
      为使用TXMusic软件（以下统称“本软件”）及服务，您应当阅读并遵守《TXMusic软件许可及服务协议》（以下简称“本协议”），以及 《https://www.qq.com/contract.shtml》、 《https://privacy.qq.com/》。 请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制腾讯责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款等，以及开通或使用某项服务的单独协议。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。<br></br>
      如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称"监护人"）的监护、陪同下阅读和判断是否同意本协议。如果您是中国大陆地区以外的用户，您订立或履行本协议以及使用本服务的行为还需要同时遵守您所属和/或所处国家或地区的法律。<br></br>
      相关定义：<br></br>
      腾讯：指深圳市腾讯计算机系统有限公司及其相关服务可能存在的运营关联单位。<br></br>
      TXMusic用户：指注册、登录、使用TXMusic软件及服务并获得管理权限的个人（“个人用户”）。<br></br>
      最终用户：指由企业用户为其开通TXMusic服务，使用TXMusic的个人用户。<br></br>
      前述“TXMusic用户”及“最终用户”统称为“用户”或“所有用户”，在本协议中更多地称为“您”。<br></br><br></br>

      ●一、【协议的范围】<br></br>
      1.1 本协议是您与腾讯之间关于您下载、安装、使用、登录本软件，以及使用本服务所订立的协议。<br></br>
      1.2 本协议的内容，包括但不限于以下与本软件和/或本服务相关的协议、规则、规范以及腾讯可能不断发布的关于本软件和/或本服务的相关协议、规则、规范等内容，前述内容一经正式发布，即为本协议不可分割的组成部分，与其构成统一整体，您同样应当遵守：<br></br>
      (1) 《腾讯服务协议》（链接地址：https://www.qq.com/contract.shtml）<br></br>
      (2) 《腾讯隐私政策》（链接地址：https://privacy.qq.com/）<br></br><br></br>

      ●二、【关于本软件和本服务】<br></br>
      2.1 本软件是指腾讯开发并按照本协议及腾讯其他协议、规则之约定授权用户下载、安装、使用的TXMusic软件。<br></br>
      2.2 您知晓并同意，腾讯可能会根据需要更新或调整本服务的内容。<br></br>
      2.3 您可以通过电脑、手机等终端以客户端、网页等形式使用本服务，具体以腾讯提供的为准，同时，腾讯会不断丰富您使用本服务的终端、形式等。当您使用本服务时，您应选择与您的终端、系统等相匹配的本软件版本，否则，您可能无法正常使用本服务。<br></br>
      2.4 腾讯给予您一项不可转让及非排他性的许可，以按照本协议及腾讯其他协议、规则限定的范围和方式使用本软件。<br></br>
      2.4.1 您可以为非商业目的在单一台终端设备上下载、安装、使用、登录本软件。<br></br>
      2.4.2 本条及本协议其他条款未明示授权的其他一切权利仍由腾讯保留，您在行使这些权利时须另外取得腾讯的书面许可。腾讯如果未行使前述任何权利，并不构成对该权利的放弃。<br></br>
      2.5 腾讯可能根据实际需要对本服务中收费服务的收费标准、方式进行修改和变更，腾讯也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，腾讯将在相应服务页面进行提前通知或公告。如果您不同意上述修改、变更或付费内容，您可以选择停止使用该服务。<br></br><br></br>

      ●三、【软件的获取】<br></br>
      3.1 您可以直接从腾讯的官方网站、官方应用分发平台以及腾讯授权的第三方网站、应用分发平台等腾讯官方或腾讯授权的渠道获取本软件。<br></br>
      3.2 如果您从未经腾讯授权的第三方获取本软件或与本软件名称相同的安装程序，腾讯无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。<br></br><br></br>

      ●四、【软件的安装与卸载】<br></br>
      4.1 腾讯可能为不同的终端、系统等开发不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。<br></br>
      4.2 下载安装程序后，您需要按照该程序提示的步骤正确安装。<br></br>
      4.3 因经营策略安排或调整等原因,不同地区的用户可使用的具体腾讯服务的内容可能会存在差异，具体以腾讯实际提供的为准。<br></br>
      4.5 如果您不再需要使用本软件或者需要安装新版软件，可以自行卸载。如果您愿意帮助腾讯改进产品服务，请告知卸载的原因。<br></br><br></br>

      ●五、【软件的更新】<br></br>
      5.1 为了增进用户体验、完善服务内容，腾讯可能不断努力开发新的服务，并为您不时提供软件更新。<br></br>
      5.2 为了改善用户体验或提高服务安全性、保证功能的一致性等目的，腾讯有权对本软件进行更新，或者对软件的部分功能效果进行改变。<br></br>
      5.3 本软件新版本发布后，旧版本的软件可能无法使用。腾讯不保证旧版本软件继续可用，也不保证继续对旧版本软件提供相应的客服和维护服务，请您随时核对并下载最新版本。<br></br><br></br>

      ●六、【用户信息及个人信息保护】<br></br>
      6.1 保护用户信息是腾讯的一项基本原则，腾讯将在法律允许的范围内按照本协议及 《腾讯隐私政策》（链接地址：https://privacy.qq.com/）、 《TXMusic隐私保护指引》的规定收集、使用、储存和分享您的相关信息。<br></br>
      6.2 您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规（本协议中的“法律法规”指用户所属/所处地区、国家现行有效的法律、行政法规、司法解释、地方法规、地方规章、部门规章及其他规范性文件以及对于该等法律法规的不时修改和补充，以及相关政策规定等，下同。）有特殊规定的，您需要填写真实的身份信息（包括但不限于手机号等信息）。若您填写的信息不完整、不真实、不规范、不合法或者腾讯有理由认为您填写的信息不完整、不真实、不规范、不合法，则您可能无法使用本服务或在使用过程中受到限制。<br></br>
      6.3 一般情况下，您可根据相关产品规则浏览、修改自己提交的信息。但出于安全性和身份识别（如帐号申诉服务等）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。<br></br>
      6.4 腾讯将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。<br></br>
      6.5 腾讯遵照法律法规的规定，对信息的分享进行严格的限制。如果确有必要，将严格参照《腾讯隐私政策》、《TXMusic隐私保护指引》或其他相关规则执行。<br></br>
      6.6 腾讯非常重视对未成年人个人信息的保护。若您不具备完全民事行为能力，在使用本服务前，应事先取得您的监护人的同意。<br></br><br></br>

      ●七、【用户行为规范】<br></br>
      7.1 【注册及帐号使用】<br></br>
      您充分理解并同意：<br></br>
      7.1.1 用户应当按照法律法规和服务要求，按相应页面的提示准确提供并及时更新用户的资料，以使之真实、及时、完整和准确。用户不得冒充他人进行注册，不得未经许可为他人注册，不得以可能误导其他用户的方式注册帐号，不得使用可能侵犯他人权益的用户名进行注册（包括但不限于涉嫌商标权、名誉权侵权等），不得用同一身份信息注册超过合理上限数量账号，不得以批量等方式恶意注册帐号，否则腾讯有权在发现后不予注册或注销该帐号，由此给用户产生的损失应由用户自行承担。<br></br>
      7.1.2 用户须确认，在用户完成注册程序或以其他腾讯允许的方式实际使用本服务时，个人用户应当是具备完全民事行为能力的自然人，如不具备完全民事行为能力，则应征得监护人的明确同意后方能注册成为本服务的个人用户；企业用户应当是合法成立并有效存续的组织，且拥有签署本协议并履行本协议项下义务所需的权利和权限。若用户不具备前述主体资格，请勿使用本服务，否则用户及用户的监护人应承担因此而导致的一切后果，且腾讯有权注销、暂时冻结或永久冻结用户的账户，给腾讯造成损失的，腾讯有权向用户及相关用户的监护人追偿。<br></br>
      7.1.3在您进行账号注册或使用本服务时，如发现您账号可能存在涉诈、虚假注册等异常情形或风险的，腾讯有权根据相关法律法规规定重新核验您的账号，并可根据风险情况，采取限期改正、限制功能、暂停使用、关闭账号、禁止重新注册以及本协议规定的其他处置措施。<br></br>
      7.1.4在您使用腾讯服务时，请务必妥善保管个人账号及密码，不得非法买卖、出租、出借个人账号。如发现您的账号可能存在异常情形或风险的，腾讯有权根据相关法律法规规定重新核验您的账号。<br></br>
      7.2 【用户注意事项】<br></br>
      您充分理解并同意：<br></br>
      7.2.1 为了向您提供更好的服务或维护软件安全的目的，腾讯将按照法律法规规定的“合法、必要、正当”的原则获取您的相关信息。<br></br>
      7.2.2 您在使用本服务某一特定功能时，可能还需同意单独的协议、规则等，您在使用该项功能前请仔细阅读前述相关协议、规则。<br></br>
      7.2.3 您可以选择不向腾讯提供您的某些信息，或者根据产品设置取消腾讯收集某些信息的权利，但因此可能会导致相关服务功能无法实现。<br></br>
      7.2.4 腾讯将会尽其商业上的合理努力保障您在本服务中的数据存储安全，但是，腾讯并不能就此提供完全保证，包括但不限于以下情形：<br></br>
      (1) 腾讯不对由于非腾讯原因造成的您在本软件和/或本服务中相关数据的删除或储存失败负责。<br></br>
      (2) 您应自行备份存储在本软件和/或本服务中的数据、信息或与本软件和/或本服务相关的数据、信息，双方另有约定的按相应约定执行。<br></br>
      (3) 如果您停止使用本软件和/或本服务（如注销您的帐号时），或因您违反法律法规或本协议而被取消或终止本服务，腾讯有权从服务器上永久地删除您的数据，如您为企业用户，腾讯还有权删除您的管理员帐号及您的最终用户基于与您的关系所产生的一切信息和权益。您的服务停止、终止或取消后，腾讯没有义务向您返还任何数据，您应自行在服务停止、终止或取消前做好备份。<br></br>
      7.3 【用户禁止行为】<br></br>
      您在使用本软件和/或本服务的过程中，应遵守相关法律法规、用户协议、规则规范等，不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利。<br></br>
      7.3.1 法律法规禁止的行为<br></br>
      您理解并同意，腾讯一致致力于为用户提供文明健康、规范有序的网络环境，您不得利用本服务账号或功能账号或本软件及服务，制作、上传、复制、发布、传送、传播或转载可能干扰本服务正常运营，或侵犯其他主体合法权益，或者违反国家法律法规规定的内容，包括但不限于：<br></br>
      (1) 反对宪法所确定的基本原则的。<br></br>
      (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。<br></br>
      (3) 损害国家荣誉和利益的。<br></br>
      (4) 歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的。<br></br>
      (5)宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的。<br></br>
      (6)煽动民族仇恨、民族歧视，破坏民族团结的。<br></br>
      (7) 破坏国家宗教政策，宣扬邪教和封建迷信的。<br></br>
      (8) 散布谣言，扰乱社会秩序，破坏社会稳定的。<br></br>
      (9) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的。<br></br>
      (10) 侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的。<br></br>
      (11)使用夸张标题，内容与标题严重不符的。<br></br>
      (12)炒作绯闻、丑闻、劣迹等的。<br></br>
      (13)不当评述自然灾害、重大事故等灾难的。<br></br>
      (14)带有性暗示、性挑逗等易使人产生性联想的。<br></br>
      (15)展现血腥、惊悚、残忍等致人身心不适的。<br></br>
      (16)煽动人群歧视、地域歧视等的。<br></br>
      (17)宣扬低俗、庸俗、媚俗内容的。<br></br>
      (18)可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的。<br></br>
      (19)侵害未成年人合法权益或者损害未成年人身心健康的内容。<br></br>
      (20)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序。<br></br>
      (21)以非法民间组织名义活动的。<br></br>
      (22) 违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的。<br></br>
      (23) 其他法律、行政法规等禁止的或对网络生态造成不良影响的内容。<br></br>
      7.3.2 软件使用<br></br>
      除非法律法规允许或腾讯书面许可，您不得从事下列行为：<br></br>
      (1) 删除本软件及其副本上关于著作权的信息。<br></br>
      (2) 对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码。<br></br>
      (3) 对腾讯拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等。<br></br>
      (4) 对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非腾讯经授权的第三方工具/服务接入本软件和相关系统。<br></br>
      (5) 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的。<br></br>
      (6) 通过非腾讯开发、授权的第三方软件、插件、外挂、系统，登录或使用本软件和/或本服务，或制作、发布、传播上述工具。<br></br>
      (7) 自行、授权他人或利用第三方软件对本软件和/或本服务及其组件、模块、数据等进行干扰。<br></br>
      (8) 其他可能影响、干扰本软件和/或本服务正常运行的行为。<br></br>
      7.3.3 危害平台安全内容<br></br>
      您不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利：<br></br>
      (1) 传播虚假中奖信息、钓鱼欺诈信息、非法或虚假理财信息等非法、诈骗或虚假信息。<br></br>
      (2) 传播包含病毒、木马等的文件、程序或相应制作教程等的内容、信息。<br></br>
      (3) 其他危害腾讯、本软件或其他主体安全的内容、信息或行为。<br></br>
      7.3.4 禁止违规出借或转卖账号及账号下资源<br></br>
      您了解并同意，TXMusic账号所有权归属腾讯公司所有，您完成TXMusic注册和开通后仅享有账号的使用权，并仅限于您本人使用，禁止赠与、借用、租用、转让或售卖TXMusic账号资源或者以其他方式许可任何其他主体使用。否则腾讯有权采取包括但不限于单方面终止服务、限制/禁用部分功能、封禁企业账号/员工账号等处置措施，并不退还您已支付费用（如有）。因此导致您及您的客户暂时或永久无法使用部分/全部功能、无法使用TXMusic登录或使用其他产品和/或服务的，您应自行承担相应后果。而且如果因您的前述导致腾讯遭受损失的，您应当一并赔偿。<br></br>
      7.4【法律责任】<br></br>
      7.4.1 您理解并同意：若您违反法律法规、本协议和/或腾讯其他协议、规则的，腾讯有权随时单方根据相应情形采取以下一项或多项措施（具体措施的时间长短由腾讯根据您的违法违约情节相应情况确定）：<br></br>
      (1)对您进行警告。<br></br>
      (2)限制您使用本软件和/或本服务部分或全部功能。<br></br>
      (3)删除、屏蔽相关内容或断开链接。<br></br>
      (4)中止、终止您对相应TXMusic帐号的使用(简称“封号”) 。<br></br>
      (5)采取其他合理、合法的措施。<br></br>
      (6)依法追究您的法律责任。<br></br>
      7.4.2 如果您违反法律法规、本协议和/或腾讯其他协议、规则，腾讯对您或您使用的本软件和/或本服务采取任何行为或措施后，导致您暂时或永久无法使用TXMusic、无法使用TXMusic登录或使用其他产品和/或服务，您应自行承担相应后果，由此造成您损失的，您应自行承担。<br></br>
      7.4.3 您应自行对因使用本服务而产生或存储在本服务中的数据，在本服务之外，采取合理、安全的备份。若腾讯根据本协议对您采取永久封号等措施的，对于您的与本服务相关的全部数据或您存储在本服务中的全部数据、信息等，腾讯有权将该等数据全部予以删除，相应后果您应自行承担。<br></br>
      7.4.4 您导致任何第三方损害的，您应当独立承担责任；腾讯因此遭受损失的，您也应当一并赔偿。<br></br>
      7.4.5 在向您提供服务过程中，如发现涉诈、冒名顶替等违法犯罪线索、风险信息的，腾讯有权依照国家有关规定移送公安、金融、电信、网信等主管部门。<br></br>
      7.5 【对自己行为负责】<br></br>
      您充分了解并同意，您必须为自己使用本软件和/或本服务的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本软件和/或本服务  时接触到的内容自行加以判断，包括因对内容的正确性、完整性或实用性的依赖而产生的风险，并承担由此引起的相应风险。腾讯在法律允许的范围内不对您因前述风险而导致的任何损失或损害承担责任。<br></br>
      7.6 【用户发送/传播违规内容与投诉处理】<br></br>
      7.6.1 未经授权和许可，用户不得利用TXMusic上传、储存、共享、传播含有受到知识产权法律保护的图像、相片、视频、软件或其他数据资料、文件。用户违反相关知识产权保护法律、规范，侵害第三方合法权益的，均由用户自行承担责任，给腾讯造成损失的应当赔偿。<br></br>
      7.6.2 如果腾讯发现或收到他人举报或投诉用户违反本协议约定的，腾讯有权将争议中相关方的主体、联系方式、投诉相关内容等必要信息提供给相关当事方或相关部门，有权不经通知随时对相关内容采取禁止发布、删除、屏蔽等措施，并视行为情节对违规主体相关账号处以包括但不限于警告、限制或禁止使用部分或全部功能、封禁账号、冻结账号直至注销账号的处罚，并公告处理结果。<br></br>
      7.6.3您理解并同意，腾讯有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对相关用户采取适当的法律行动，并依法保存有关信息，向有关部门报告及提供账号相关信息，配合调查处理等，用户应独自承担由此而产生的一切法律责任。<br></br>

      ●八、【广告】<br></br>
      8.1 您同意腾讯可以自行或由第三方通过短信、电子邮件或电子信息等多种方式向您发送、展示广告或其他信息（包括商业与非商业信息），广告或其他信息的具体发送及展示形式、频次及内容等以腾讯实际提供为准。<br></br>
      8.2 腾讯将依照相关法律法规要求开展广告业务。您同意，对本服务中出现的广告，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对因该广告而实施的行为负责。<br></br><br></br>

      ●九、【知识产权】<br></br>
      9.1 腾讯是本软件的知识产权权利人。本软件的著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受法律法规和相应的国际条约保护，腾讯依法享有上述相关知识产权，但相关权利人依照法律规定应享有的权利除外。<br></br>
      9.2 未经腾讯或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。<br></br><br></br>

      ●十、【终端安全责任】<br></br>
      10.1 您理解并同意，本软件和/或本服务同大多数互联网软件、服务一样，可能会受多种因素影响（包括但不限于用户原因、网络服务质量、社会环境等）；也可能会受各种安全问题的侵扰（包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您终端的信息和数据的安全，继而影响本软件、本服务的正常使用等），因此，您应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。<br></br>
      10.2 您不得制作、发布、使用、传播用于窃取TXMusic号码和/或他人个人信息、财产的恶意程序。<br></br>
      10.3 维护软件安全与正常使用是腾讯和您的共同责任，腾讯将按照行业标准合理审慎地采取必要技术措施保护您的终端信息和数据安全，但是您承认和同意腾讯不能就此提供任何保证。<br></br><br></br>

      ●十一、【服务费用】<br></br>
      11.1 腾讯保留本软件的任何服务或功能后续收费的权利。腾讯有权提前10个工作日以公告形式通知用户收费标准，若用户继续使用则需按腾讯公布的收费标准支付费用。<br></br>
      11.2 腾讯有权根据实际情况单方调整费用标准及收费方式，并提前10个工作日以公告形式通知你，而无需向用户特别通知或获得用户的事先同意，如用户不同意收费，用户应当立即停止服务的使用，否则使用则视为用户已同意并应当缴纳费用。<br></br>
      11.3 用户应当自行支付使用本软件可能产生的上网费以及其他第三方收取的通讯费、信息费等。<br></br><br></br>

      ●十二、【第三方软件或技术】<br></br>
      12.1 本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。<br></br>
      12.2 本软件如果使用了第三方的软件或技术，腾讯将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以“软件使用许可协议”“授权协议”“开源代码许可证”或其他形式来表达。<br></br>
      12.3 前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求腾讯给予协助，您应当自行承担法律责任。<br></br><br></br>

      ●十三、【不可抗力及其他免责事由】<br></br>
      13.1 您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务受到影响。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，腾讯将努力在第一时间与相关单位配合，争取及时进行处理，但是由此给您造成的损失腾讯在法律允许的范围内免责。<br></br>
      13.2 在法律允许的范围内，腾讯对以下情形导致的服务中断或受阻不承担责任：<br></br>
      (1)受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏。<br></br>
      (2)用户或腾讯的电脑软件、系统、硬件和通信线路出现故障。<br></br>
      (3)用户操作不当或用户通过非腾讯授权的方式使用本服务。<br></br>
      (4)程序版本过时、设备的老化和/或其兼容性问题。<br></br>
      (5)其他腾讯无法控制或合理预见的情形。<br></br>
      13.3 您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，腾讯不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：<br></br>
      (1)来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息。<br></br>
      (2)遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失。<br></br>
      (3)其他因网络信息或用户行为引起的风险。<br></br>
      13.4 腾讯依据本协议约定获得处理违法违规内容的权利，该权利不构成腾讯的义务或承诺，腾讯不能保证及时发现违法行为或进行相应处理。<br></br>
      13.5 在任何情况下，您不应轻信借款、索要密码或其他涉及财产的信息。涉及财产操作的，请一定先核实对方身份，并请经常留意腾讯有关防范诈骗犯罪的提示。<br></br><br></br>


      ●十四、【其他】<br></br>
      14.1 您使用本软件和/或本服务即视为您已阅读本协议并接受本协议的约束。腾讯有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用腾讯提供的软件或服务，即视为您已接受变更后的协议。<br></br>
      14.2 如果您未满18周岁，请在法定监护人陪同下阅读本协议及其他相关协议，并特别注意与未成年人使用相关的条款，在取得法定监护人的同意后，在法定监护人监护、指导下使用使用本服务。<br></br>
      14.3 本协议签订地为中华人民共和国广东省深圳市南山区。<br></br>
      14.4 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。<br></br>
      14.5 若您和腾讯之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即广东省深圳市南山区）有管辖权的人民法院管辖。<br></br>
      14.6 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。<br></br>
      14.7 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。<br></br>
      14.8 若本协议有中文、英文等多个语言版本，相应内容不一致的，均以中文版为准。<br></br><br></br>

      ●十五、【联系我们】<br></br>
      当您对我们的产品有相关建议或者有其它相关事宜需要我们协助，或需对发现的违规内容进行投诉、举报，可通过“用户主页”——“帮助与客服”——“问题反馈”提交相应材料；或通过 https://kf.qq.com/ 等方式联系我们，我们将尽快审核所涉问题，并在法律法规规定的期限内回复您的请求。<br></br><br></br>

      深圳市腾讯计算机系统有限公司
    </div>
  );
};

export default Service;