import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Popup, Loading } from 'tdesign-mobile-react'
import styled from 'styled-components'
import styles from './Home.module.css'

// Import login
import { useAuth } from '../../hooks'

// Import components
import ImgMusic from './components/ImgMusic/ImgMusic'
import VideoMusic from './components/VideoMusic/VideoMusic'
import TagMusic from './components/TagMusic/TagMusic'
import TextMusic from './components/TextMusic/TextMusic'
import HotMusic from './components/HotMusic/HotMusic'
import RecordMusic from './components/RecordMusic/RecordMusic'

import BottomNav from '../../components/BottomNav/BottomNav'
import { isWeiXin } from '../../components/wxApi'
import { doFetch } from '../../components'




const Home = () => {
  const { login } = useAuth()
  const navigate = useNavigate()
  const [showLoading, setShowLoading] = useState(false)

  // Ref
  const audioRef = useRef(null)
  // State
  const [songs, setSongs] = useState([])
  const [currentSong, setCurrentSong] = useState({})
  const [isPlaying, setIsPlaying] = useState(false)
  const [songInfo, setSongInfo] = useState({
    currentTime: 0,
    duration: 0,
  })


  useEffect(() => {

  }, [])

  const [type, setType] = useState('hot')
  const switchType = (type) =>{
    setType(type)
  }

  let tabContent;
  if (type === 'hot') {
    tabContent = <HotMusic setType={setType}/>
  } else if (type === 'img') {
    tabContent = <ImgMusic />
  } else if (type === 'text') {
    tabContent = <TextMusic />
  } else if (type === 'tag') {
    tabContent = <TagMusic />
  } else if (type === 'video') {
    tabContent = <VideoMusic />
  } else if (type === 'record') {
    tabContent = <RecordMusic />
  } 

  return (
    <div className={styles.wrap}>
      <div className={styles.tabs}>
        <div className={`${styles.tabsLabel} ${type == 'hot' ? styles.cur : ''}`} onClick={() => switchType('hot')}>
          热门
          {type == 'hot' ? 
          (<img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-hot2.png"></img>) : 
          (<img src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/icon-hot.png"></img>)}

        </div>
        <div className={`${styles.tabsLabel} ${type == 'img' ? styles.cur : ''}`} onClick={() => switchType('img')}>图片</div>
        <div className={`${styles.tabsLabel} ${type == 'text' ? styles.cur : ''}`} onClick={() => switchType('text')}>文字</div>
        <div className={`${styles.tabsLabel} ${type == 'tag' ? styles.cur : ''}`} onClick={() => switchType('tag')}>标签</div>
        <div className={`${styles.tabsLabel} ${type == 'video' ? styles.cur : ''}`} onClick={() => switchType('video')}>视频</div>
        <div className={`${styles.tabsLabel} ${type == 'record' ? styles.cur : ''}`} onClick={() => switchType('record')}>哼唱</div>
      </div>
      {tabContent}

      <BottomNav></BottomNav>
      
    </div>
  )
}

const AppContainer = styled.div`
  transition: all 0.5s ease;
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`

export default Home
