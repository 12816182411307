import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Input, Button, Toast } from 'tdesign-mobile-react'
import { LogoWechatIcon } from 'tdesign-icons-react'

import { captchaId } from '../../config'
import { useAuth } from '../../hooks'
import { doFetch } from '../../components'
import { isWeiXin, getWxCode } from '../../components/wxApi'
import { phoneNumberValidator, verifyCodeValidator } from './components/validator'
import styles from './Login.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';

function Login() {
  const { login } = useAuth()
  const navigate = useNavigate()
  const location = useLocation();

  const [isSubscribed, setIsSubscribed] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState({ value: '', error: '' })
  const [verifyCode, setverifyCode] = useState({ value: '', error: '' })
  const [canSend, setcanSend] = useState(true)
  const [timer, settimer] = useState(60)
  const time = useRef(null)
  
  const [isLoading,setIsLoading] = useState(false)

  const onCLinckWeixinLogin = () => {
    if (!isSubscribed) {
      Toast({ message: '请同意服务协议和隐私政策' })
      return
    }

    setIsLoading(true);
    getWxCode(window.location.href, 'login')
  }

  //获取wxcode
  const [wxCode, setWxCode] = useState(null)
  const query = new URLSearchParams(useLocation().search)
  useEffect(() => {
    setWxCode(query.get('code'))
    if (wxCode) {
      loginWx(wxCode)
    }
  }, [wxCode])

  const loginWx = async (code) => {
    setIsLoading(true)
    const loginRes = await login({
      code: code,
      type: isWeiXin() ? 'jsapi' : 'h5',
    })
    if (!loginRes) {
      setIsLoading(false)
      navigate('/login', { replace: true })
    } else {
      setIsLoading(false)
      //回跳到传进来的uri
      if(query.get('uri')){
        navigate(query.get('uri'), { replace: true })
      }else{
        //查看from是否有值，没有则跳首页
        const from = location.state?.from?.pathname || "/";
        console.log(location);

        navigate(from, { replace: true });
      }
    }
  }
  

  const captchaCallback = async (res) => {
    if (res.ret !== 0) return
    const captchaRes = await doFetch(process.env.REACT_APP_HOST, '/captcha', 'POST', {
      ticket: res.ticket,
      randstr: res.randstr,
    })
    if (captchaRes.ok) {
      const loginRes = await login({
        type: 'phone',
        phone: {
          phone: '+86' + phoneNumber.value,
          code: verifyCode.value,
        },
      })
      if (!loginRes) {
        setverifyCode({ ...verifyCode, error: '请输入正确的验证码' })
      }
    }
  }

  const captchaLoadErrorCallback = () => {
    var appid = ''
    var ticket = 'terror_1001_' + appid + Math.floor(new Date().getTime() / 1000)
    captchaCallback({
      ret: 0,
      randstr: '@' + Math.random().toString(36).substr(2),
      ticket: ticket,
      errorCode: 1001,
      errorMessage: 'jsload_error',
    })
  }

  const onClickPhoneLogin = () => {
    const phoneNumberError = phoneNumberValidator(phoneNumber.value)
    const verifyCodeError = verifyCodeValidator(verifyCode.value)
    if (phoneNumberError || verifyCodeError) {
      setPhoneNumber({ ...phoneNumber, error: phoneNumberError })
      setverifyCode({ ...verifyCode, error: verifyCodeError })
      return
    }
    if (!isSubscribed) {
      Toast({ message: '请同意服务协议和隐私政策' })
      return
    }

    try {
      let captcha = new window.TencentCaptcha(captchaId, captchaCallback, {})
      captcha.show()
    } catch {
      captchaLoadErrorCallback()
    }
  }

  // const sendCode = async () => {
  //   const phoneNumberError = phoneNumberValidator(phoneNumber.value)
  //   if (phoneNumberError) {
  //     setPhoneNumber({ ...phoneNumber, error: phoneNumberError })
  //     return
  //   }

  //   const body = { phone: '+86' + phoneNumber.value }
  //   const response = await doFetch(process.env.REACT_APP_HOST, '/sms', 'POST', body)
  //   const respJson = await response.json()
  //   if (respJson.code === 0) {
  //     settimer(respJson.response.ttl)
  //     setcanSend(false)
  //     time.current = setInterval(() => {
  //       settimer((timer) => timer - 1)
  //     }, 1000)
  //   } else if (respJson.code === 11023) {
  //     settimer(respJson.response.ttl)
  //     setcanSend(false)
  //     time.current = setInterval(() => {
  //       settimer((timer) => timer - 1)
  //     }, 1000)
  //     setverifyCode({ ...verifyCode, error: '验证码已发送，在倒计时结束前有效' })
  //   } else {
  //     setcanSend(true)
  //     clearInterval(time.current)
  //     setverifyCode({ ...verifyCode, error: respJson.message })
  //     return
  //   }
  // }

  useEffect(() => {
    if (timer === 0) {
      setcanSend(true)
      clearInterval(time.current)
    }
  }, [timer])

  const handleChange = (event) => {
    setIsSubscribed((current) => !current)
  }

  return (
    <>
      <div className={styles.containerLogin}>
        {/* <img src={process.env.PUBLIC_URL + '/logo512.png'} alt=""></img> */}
        <div className={styles.title}>TXMusic</div>
        <div className={styles.subTitle}>- AI音乐创作，触手可得 -</div>

        <div className={styles.actions}>
          <Button size="large" theme="primary" onClick={onCLinckWeixinLogin} loading={isLoading}>
            微信授权登录
          </Button>
          {/* <div className={styles.consent}>-或-</div>
          <div>
            <Input
              label={'手机号'}
              placeholder="请输入手机号"
              type="number"
              value={phoneNumber.value}
              maxcharacter={11}
              onChange={(text) => {
                setPhoneNumber({ value: text, error: '' })
              }}
              errorMessage={phoneNumber.error}
            />
            <Input
              label={'验证码'}
              placeholder="请输入验证码"
              type="number"
              value={verifyCode.value}
              onChange={(text) => {
                setverifyCode({ value: text, error: '' })
              }}
              errorMessage={verifyCode.error}
              suffix={
                <Button disabled={!canSend} onClick={sendCode} theme="primary" variant="text">
                  {canSend ? '发送验证码' : timer + '秒后重发'}
                </Button>
              }
            />
          </div>
          <Button size="large" variant="outline" onClick={onClickPhoneLogin}>
            手机登录
          </Button> */}
        </div>

        <div className={styles.consent}>
          <input type="checkbox" value={isSubscribed} onChange={handleChange} /> 
          <div>
            我已阅读、理解并同意
            <Link to="/policy?type=service">《服务条款》</Link>
            <Link to="/policy?type=privacy">《隐私政策》</Link>
            <Link to="/policy?type=open">《开源版权声明》</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
