import styles from './Step3.module.scss'

import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect } from 'react'
import { Button, Upload, Popup, Toast } from 'tdesign-mobile-react';
import CustomLoading from '../../../components/CustomLoading/CustomLoading'

import { doFetch } from '../../../components'
import AudioPlayer from '../../../components/AudioPlayer/AudioPlayer'

let timerHandle;

function Step2() {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMeg, setLoadingMeg] = useState('');

    const [duration, setDuration] = useState(45);
    const durationRef = useRef(45);

    const [curIndex, setCurIndex] = useState(0);

    const [isPlaying1, setIsPlaying1] = useState(false);
    const isPlaying1Ref = useRef(false);
    const [audioUrl1, setAudioUrl1] = useState('');
    const [audioUrl2, setAudioUrl2] = useState('');
    const [isPlaying2, setIsPlaying2] = useState(false);
    const isPlaying2Ref = useRef(false);

    const [instrumentMusics, setInstrumentMusics] = useState(null);

    const [typesCount1, setTypesCount1] = useState([0, 0, 0, 0, 0, 0, 0])
    const [typesCount2, setTypesCount2] = useState([0, 0, 0, 0, 0, 0, 0])
    const [typesCount3, setTypesCount3] = useState([0, 0, 0, 0, 0, 0, 0])


    const navigate = useNavigate();

    useEffect(() => {
        if (sessionStorage.getItem('gameId')) {
            countDownFn();

            if (sessionStorage.getItem('instrumentMusics')) {
                let instrumentMusics = JSON.parse(sessionStorage.getItem('instrumentMusics'))
                setAudioUrl1(instrumentMusics[0].url)
                setAudioUrl2(instrumentMusics[1].url)

                setInstrumentMusics(instrumentMusics);


                typesCount(instrumentMusics[0].noteRatio, instrumentMusics[1].noteRatio, sessionStorage.getItem("noteRatio"))
            }
        } else {
            navigate('/')
        }
    }, [])

    const typesCount = (obj1, obj2, obj3) => {
        obj3 = JSON.parse(obj3);

        let max1 = Math.max(...Object.values(obj1));
        let max2 = Math.max(...Object.values(obj2));
        let max3 = Math.max(...Object.values(obj3));

        

        let count1 = Math.ceil(10 / max1);
        let count2 = Math.ceil(10 / max2);
        let count3 = Math.ceil(10 / max3);

        let typesCount1 = [];
        let typesCount2 = [];
        let typesCount3 = [];

        for (let key in obj1) {
            typesCount1.push(Math.ceil(obj1[key] * count1))
            typesCount2.push(Math.ceil(obj2[key] * count2))
            typesCount3.push(Math.ceil(obj3[key] * count3))
        }
 

        draw(typesCount3,'charts1');
        draw(typesCount3,'charts2');
       

        setTypesCount1(typesCount1)
        setTypesCount2(typesCount2)
        setTypesCount3(typesCount3)

    }

    const draw = (data,id) => {
        // 获取屏幕宽度
        const screenWidth = 360;
        const scale = screenWidth/375;
        // 横向和竖向的间距
        const hSpacing = Math.ceil(45*scale);
        const vSpacing = 11;
        // 竖向最大值
        const maxValue = 12;

        // 获取Canvas上下文
        const ctx = document.getElementById(id).getContext('2d');

        // 设置线条颜色为白色
        ctx.strokeStyle = '#ffffff';
        ctx.lineWidth = 1; // 你可以根据需要调整这个值
        //ctx.setLineDash([10, 5]);

        // 转换数据点为canvas坐标
        const points = data.map((value, index) => {
            return {
                x: 22 + index * hSpacing,
                y: (maxValue - (value ? value : 0)) * vSpacing // 因为canvas的y坐标是向下增长的，所以用最大值减去实际值
            };
        });

        // 绘制折线
        ctx.beginPath();
        points.forEach((point, index) => {
            if (index === 0) {
                ctx.moveTo(point.x, point.y);
            } else {
                ctx.lineTo(point.x, point.y);
            }
        });
        ctx.stroke();

        // 绘制数据点
        points.forEach(point => {
            ctx.beginPath();
            ctx.arc(point.x, point.y, 2, 0, 2 * Math.PI); // 绘制点
            ctx.fillStyle = '#ffffff'; // 设置数据点颜色为白色
            ctx.fill();
        });

        // 将以上绘制的内容显示到canvas上
        //ctx.draw();

    }

    const countDownFn = () => {
        timerHandle = setInterval(() => {
            if (durationRef.current == 0) {
                clearInterval(timerHandle)
                nextStep();
            } else {
                let duration = durationRef.current - 1
                durationRef.current = duration;

                if (duration < 10) {
                    duration = '0' + duration
                }

                setDuration(duration);
            }
        }, 1000)
    }

    const handlePlayPause1 = (shouldPlay) => {
        setIsPlaying1(shouldPlay)
        isPlaying1Ref.current = shouldPlay

        if (isPlaying2Ref.current) {
            setIsPlaying2(false)
        }

    }

    const handlePlayPause2 = (shouldPlay) => {
        setIsPlaying2(shouldPlay)
        isPlaying2Ref.current = shouldPlay

        if (isPlaying1Ref.current) {
            setIsPlaying1(false)
        }
    }

    const nextStep = async () => {
        //Toast({ message: '正在生成完整音乐作品...' });
        setLoadingMeg('正在生成完整音乐作品...')
        setIsLoading(true)

        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game', 'POST', {
                "action": "submitSelectionResult",
                "submitSelectionResultRequest": {
                    "gameId": sessionStorage.getItem('gameId'),
                    "instrumentMusicId": instrumentMusics[curIndex].instrumentMusicId
                }
            })
            let respJson = await res.json()

            if (respJson.code === 0) {
                setTimeout(() => {
                    getJobs();
                }, 5000)
            }
            else if (respJson.code === 401) {
                navigate('/login?uri=' + encodeURIComponent('/'));
            }
            else if (respJson.code === 11024 || respJson.code === 11001) {
                setIsLoading(false);
                Toast.fail({ message: '本轮游戏已结束' })
                setTimeout(() => {
                    navigate('/')
                    sessionStorage.setItem('gameId', '')
                }, 2500)
            }
            else {
                setIsLoading(false);
                Toast.fail({ message: '制作失败，请重试' })
            }

        } catch (err) {
            setIsLoading(false);
            Toast.fail({ message: '制作失败，请重试' })
            return false;
        }
    }

    const getJobs = async () => {

        setIsLoading(true);
        let timer = null;

        try {
            const res = await doFetch(process.env.REACT_APP_HOST, '/v1/game/' + sessionStorage.getItem('gameId'), 'GET')
            let respJson = await res.json()

            setIsLoading(false);
            if (respJson.code === 0) {
                if (respJson.response.state == 5 || respJson.response.state == 9) {
                    clearTimeout(timer); //清理定时任务
                    navigate('/game/step4?gameId=' + sessionStorage.getItem('gameId') + '&routeFrom=step3');
                } else if (respJson.response.state == 7 || respJson.response.state == 8) {
                    clearTimeout(timer); //清理定时任务
                    Toast.fail({ message: '本轮游戏已结束' })

                    setTimeout(() => {
                        navigate('/')
                        sessionStorage.setItem('gameId', '')
                    }, 2500)
                } else {
                    timer = setTimeout(() => {
                        getJobs()
                    }, 2000);
                }
            }
            else if (respJson.code === 401) {
                navigate('/login?uri=' + encodeURIComponent('/'));
            }
            else if (respJson.code === 11024) {
                Toast.fail({ message: '本轮游戏已结束' })
                setTimeout(() => {
                    navigate('/')
                    sessionStorage.setItem('gameId', '')
                }, 2500)
            }
            else {
                setIsLoading(false);
                Toast.fail({ message: '制作失败，请重试' })
            }

        } catch (err) {
            console.log(err);
            setIsLoading(false);
            Toast.fail({ message: '生成失败，请重试' })
            return false;
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>Step3 创作音乐</div>
            <div className={styles.desc} >挑选主乐器片段，与其他乐手共创完整音乐</div>
            <div className={styles.step} >
                <div className={styles.stepLine + ' ' + styles.deepColor}></div>
                <div className={styles.stepLine + ' ' + styles.deepColor}></div>
                <div className={styles.stepLine}></div>

                <div className={styles.stepRound + ' ' + styles.round1 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round2 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round3 + ' ' + styles.deepColor}></div>
                <div className={styles.stepRound + ' ' + styles.round4}></div>
            </div>

            <div className={styles.countdown}>
                <div className={styles.time}>00:{duration}</div>
                <div className={styles.desc1}>请您根据个人品味，在倒计时结束前选择参与创作的片段</div>
            </div>

            <div className={styles.block + ' ' + styles.bg1}>
                <div className={styles.blockSelect} onClick={() => { setCurIndex(0) }}>
                    {curIndex === 0 &&
                        <img className={styles.gou}
                            src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/icon-gou.png" ></img>
                    }
                    {curIndex !== 0 &&
                        <img className={styles.gou}
                            src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/icon-gou2.png" ></img>
                    }

                    片段1
                </div>
                <div className={styles.blockExample}>
                    <div className={styles.exampleItem}>
                        <span className={styles.iconColor1}></span>音乐音符
                    </div>
                    <div className={styles.exampleItem}>
                        <span className={styles.iconColor2}></span>游戏音符
                    </div>
                </div>
                {!isPlaying1 &&
                    <img className={styles.play} onClick={() => { handlePlayPause1(true) }}
                        src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/play.png" ></img>
                }

                {isPlaying1 &&
                    <img className={styles.play} onClick={() => { handlePlayPause1(false) }}
                        src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/stop.png" ></img>
                }

                <div className={styles.musicPlay}>
                    <AudioPlayer
                        src={audioUrl1}
                        onPlayPause={handlePlayPause1}
                        isPlaying={isPlaying1}
                        setIsPlaying={setIsPlaying1}
                    ></AudioPlayer>
                </div>


                <div className={styles.symbols}>
                    <div className={styles.symbol}>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount1[0] ? styles.bright : '')} key={index}></div>
                            ))}
                            Do
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount1[1] ? styles.bright : '')} key={index}></div>
                            ))}
                            Re
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount1[2] ? styles.bright : '')} key={index}></div>
                            ))}
                            Mi
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount1[3] ? styles.bright : '')} key={index}></div>
                            ))}
                            Fa
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount1[4] ? styles.bright : '')} key={index}></div>
                            ))}
                            Sol
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount1[5] ? styles.bright : '')} key={index}></div>
                            ))}
                            La
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount1[6] ? styles.bright : '')} key={index}></div>
                            ))}
                            Si
                        </div>
                    </div>
                    <div className={styles.symbol + ' ' + styles.symbol2}>
                        <canvas className={styles.charts} id="charts1" canvas-id="charts1"></canvas>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[0] ? styles.bright : '')} key={index}></div>
                            ))}
                            Do
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[1] ? styles.bright : '')} key={index}></div>
                            ))}
                            Re
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[2] ? styles.bright : '')} key={index}></div>
                            ))}
                            Mi
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[3] ? styles.bright : '')} key={index}></div>
                            ))}
                            Fa
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[4] ? styles.bright : '')} key={index}></div>
                            ))}
                            Sol
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[5] ? styles.bright : '')} key={index}></div>
                            ))}
                            La
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[6] ? styles.bright : '')} key={index}></div>
                            ))}
                            Si
                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.block + ' ' + styles.bg2}>
                <div className={styles.blockSelect} onClick={() => { setCurIndex(1) }}>
                    {curIndex === 1 &&
                        <img className={styles.gou}
                            src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/icon-gou.png" ></img>
                    }
                    {curIndex !== 1 &&
                        <img className={styles.gou}
                            src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/step3/icon-gou2.png" ></img>
                    }
                    片段2
                </div>
                <div className={styles.blockExample}>
                    <div className={styles.exampleItem}>
                        <span className={styles.iconColor1}></span>音乐音符
                    </div>
                    <div className={styles.exampleItem}>
                        <span className={styles.iconColor2}></span>游戏音符
                    </div>
                </div>
                {!isPlaying2 &&
                    <img className={styles.play} onClick={() => { handlePlayPause2(true) }}
                        src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/play.png" ></img>
                }

                {isPlaying2 &&
                    <img className={styles.play} onClick={() => { handlePlayPause2(false) }}
                        src="https://medialab-music-mini-programs-1251316161.cos.ap-guangzhou.myqcloud.com/web_image/game/index/stop.png" ></img>
                }

                <div className={styles.musicPlay}>
                    <AudioPlayer
                        src={audioUrl2}
                        onPlayPause={handlePlayPause2}
                        isPlaying={isPlaying2}
                        setIsPlaying={setIsPlaying2}
                    ></AudioPlayer>
                </div>

                <div className={styles.symbols}>
                    <div className={styles.symbol}>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount2[0] ? styles.bright : '')} key={index}></div>
                            ))}
                            Do
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount2[1] ? styles.bright : '')} key={index}></div>
                            ))}
                            Re
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount2[2] ? styles.bright : '')} key={index}></div>
                            ))}
                            Mi
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount2[3] ? styles.bright : '')} key={index}></div>
                            ))}
                            Fa
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount2[4] ? styles.bright : '')} key={index}></div>
                            ))}
                            Sol
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount2[5] ? styles.bright : '')} key={index}></div>
                            ))}
                            La
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount2[6] ? styles.bright : '')} key={index}></div>
                            ))}
                            Si
                        </div>
                    </div>
                    <div className={styles.symbol + ' ' + styles.symbol2}>
                        <canvas className={styles.charts} id="charts2" canvas-id="charts2"></canvas>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[0] ? styles.bright : '')} key={index}></div>
                            ))}
                            Do
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[1] ? styles.bright : '')} key={index}></div>
                            ))}
                            Re
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[2] ? styles.bright : '')} key={index}></div>
                            ))}
                            Mi
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[3] ? styles.bright : '')} key={index}></div>
                            ))}
                            Fa
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[4] ? styles.bright : '')} key={index}></div>
                            ))}
                            Sol
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[5] ? styles.bright : '')} key={index}></div>
                            ))}
                            La
                        </div>
                        <div className={styles.symbolList}>
                            {Array(12).fill().map((item, index) => (
                                <div className={styles.symbolItem + ' ' + ((12 - index) <= typesCount3[6] ? styles.bright : '')} key={index}></div>
                            ))}
                            Si
                        </div>
                    </div>

                </div>
            </div>


            <Button size="medium" theme="primary" className={styles.btn} onClick={nextStep}>确定选择</Button>


            {isLoading && <CustomLoading message={loadingMeg}></CustomLoading>}
        </div>
    )
}

export default Step2